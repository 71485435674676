import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Button, Checkbox, Container, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import Loader from '../components/Loader'

export default function SingleReport (props) {

    const { id } = props.match.params

    const [data,setdata] = useState(null)

    useEffect(() => {
        firebase.getCollection('reportes').doc(id).onSnapshot(snap => {
            setdata(snap.data())
        })
    },[id])

    return data ? (
        <Container maxWidth="sm">
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell variant="head">Fecha</TableCell>
                        <TableCell>{moment(data.timestamp).format('DD-MM-YYYY hh:mm a')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Nombre</TableCell>
                        <TableCell>{data.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Teléfono</TableCell>
                        <TableCell>{data.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Email</TableCell>
                        <TableCell>{data.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Nombre</TableCell>
                        <TableCell>{data.name}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell variant="head">Título</TableCell>
                        <TableCell>{data.title}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Area</TableCell>
                        <TableCell>{data.area}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Tipo</TableCell>
                        <TableCell>{data.type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Descripción</TableCell>
                        <TableCell>{data.description}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Archivo</TableCell>
                        <TableCell>{data.file ? <Button color="primary" variant="contained" component="a" href={data.file} target="_blank" rel="noopener noreferrer">Descargar</Button> : '** No hay archivo **'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Ubicación</TableCell>
                        <TableCell>{data.latitude ? <Button variant="contained" component="a" href={`https://www.google.com/maps/place/${data.latitude},${data.longitude}`} target="_blank" rel="noopener noreferrer">Ver en mapa</Button> : ''}</TableCell>
                    </TableRow><TableRow>
                        <TableCell variant="head">Marcar como finalizado</TableCell>
                        <TableCell><Checkbox
                            checked={data.isRead}
                            color="primary"
                            onChange={handleComplete}
                        /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    ) : <Loader/>

    async function handleComplete () {
        try {
            const time = moment().valueOf()
            firebase.update('reportes',id,'isRead',data.isRead ? !data.isRead : true).then(() => {
                firebase.update('reportes',id,'end',time)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}