import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Checkbox, Container, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import moment from 'moment'

const Item = ({ item }) => {

    return (
        <TableRow>
            <TableCell>{item.id}</TableCell>
            <TableCell>{moment(item.timestamp).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{moment(item.date).format('DD-MM-YYYY hh:mm a')}</TableCell>
            <TableCell>{item.tramite}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.phone}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell align="center"><Checkbox
                checked={item.isRead}
                color="primary"
                onChange={handleComplete}
            /></TableCell>
        </TableRow>
    )

    async function handleComplete () {
        try {
            const time = moment().valueOf()
            firebase.update('citas',item.id,'isRead',!item.isRead).then(() => {
                firebase.update('citas',item.id,'end',time)
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default function ProcedureArea (props) {

    const { area } = props.match.params

    const [list,setlist] = useState([])
    
    useEffect(() => {
        firebase.getCollection('citas').where('area','==',area).onSnapshot(snap => {
            const data = snap.docs.map(item => ({ ...item.data(), id: item.id }))
            setlist(data)
        })
    },[area])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head">Folio</TableCell>
                        <TableCell variant="head">Fecha Regístro</TableCell>
                        <TableCell variant="head">Fecha y Hora de Cita</TableCell>
                        <TableCell variant="head">Trámite</TableCell>
                        <TableCell variant="head">Nombre</TableCell>
                        <TableCell variant="head">Teléfono</TableCell>
                        <TableCell variant="head">Email</TableCell>
                        <TableCell variant="head">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        list.map(item => <Item key={item.id} item={item} />)
                    }
                </TableBody>
            </Table>
        </Container>
    )
}