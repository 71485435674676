import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { Container, Button, Box, Table, TableHead, TableRow, TableCell, TableBody, Typography, TextField, IconButton } from '@material-ui/core';
import { css } from '@emotion/core';
import _ from 'lodash';

import DeleteIcon from '@material-ui/icons/Delete';

import Modal from '../components/Modal';

const Item = ({ data }) => {

    return (
        <TableRow>
            <TableCell>
                <IconButton color="primary" onDoubleClick={() => firebase.delete('images',data.id,data)}><DeleteIcon /></IconButton>
            </TableCell>
            <TableCell><img css={css`
                width: 5rem;
            `} src={data.file} alt={data.title} /></TableCell>
            <TableCell>{data.title}</TableCell>
        </TableRow>
    )
}

export default function Images () {

    const [open, setOpen] = useState(false);
    const [image,setimage] = useState({});
    const [images,setimages] = useState([]);
    const [loading,setloading] = useState(false);

    useEffect(() => {
        firebase.getCollection('images').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setimages(data)
        })
    },[])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (index,val) => {
        const newItem = {...image}
        newItem[index] = val
        setimage(newItem)
    }

    return (
        <Container>
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleOpen} variant="contained" color="secondary">+ Agregar Imagen</Button>
            </Box>
            <Table style={{ marginTop: '1rem' }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Eliminar</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Imagen</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="center" variant="head">Título</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        images.map(item => <Item key={item.id} data={item} />)
                    }
                </TableBody>
            </Table>
            <Modal open={open} handleClose={handleClose}>
                <Typography color="primary" align="center" variant="h4" component="h2">Nueva Imagen</Typography>
                <TextField
                    label="Título"
                    value={image.title}
                    onChange={e => handleChange('title',e.target.value)}
                    fullWidth
                    variant="outlined"
                    style={{ marginTop: '1rem' }}
                />
                <TextField
                    label="Imagen"
                    onChange={e => handleChange('file',e.target.files[0])}
                    fullWidth
                    type="file"
                    style={{ marginTop: '1rem' }}
                />
                <Button disabled={loading} onClick={handleAdd} fullWidth variant="outlined" style={{ marginTop: '1rem' }} color="secondary">Agregar</Button>
            </Modal>
        </Container>
    )

    async function handleAdd () {
        try {
            setloading(true)
            if (image.title && image.file) {
                // firebase.simpleAdd(image,'images').then(() => {
                //     alert('imageo agregado')
                //     handleClose()
                // })
                const item = {
                    title: image.title
                }
                firebase.addNew(image.file,item).then(() => {
                    alert('Imagen agregada')
                    handleClose()
                    setloading(false)
                })
            } else {
                alert('Completa todos los campos para continuar')
                setloading(false)
            }
        } catch (error) {
            alert(error.message)
            setloading(false)
        }
    }
}