import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Container, Grid, Paper, Typography } from '@material-ui/core'
import moment from 'moment'
import { css } from '@emotion/core'
import _ from 'lodash'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom'

const Item = ({ item }) => {

    const diff = moment().diff(moment(item.timestamp))
    const dur = moment.duration(diff).asHours()

    return (
        <TableRow key={item.timestamp} sm={12} md={6}>
            <TableCell css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}>{item.id}</TableCell>
            <TableCell css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}>{moment(item.timestamp).format('DD-MM-YYYY')}</TableCell>
            <TableCell css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}>{item.type}</TableCell>
            <TableCell css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}>{item.name}</TableCell>
            <TableCell css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}>{item.email}</TableCell>
            <TableCell css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}>{item.isRead !== true ? `${dur.toFixed(0)} horas` : `Completado el ${moment(item.end).format('DD-MM-YYYY hh:mm a')}`}</TableCell>
            <TableCell align="center" css={item.isRead === true ? '' : css`
                background-color: rgba(220,20,60,.2);
            `}><IconButton component={Link} to={`/reportes/${item.id}`} variant="contained" color="primary"><ArrowForwardIosIcon/></IconButton></TableCell>
        </TableRow>
    )
}

export default function Reports (props) {

    const [reqs,setreqs] = useState([])

    useEffect(() => {
        if (props.user?.completePerms === true) {
            firebase.getCollection('reportes').orderBy('timestamp').onSnapshot(snap => {
                const data = snap.docs.map(doc => ({...doc.data(), id: doc.id}))
                setreqs(data)
            })
        } else {
            firebase.getCollection('reportes').orderBy('timestamp').onSnapshot(snap => {
                const data = snap.docs.map(doc => ({...doc.data(), id: doc.id}))
                setreqs(data)
            })
        }
    },[props.user])

    const complete = _.filter(reqs, o => {
        return o.isRead === true
    })
    const size = _.size(reqs)
    const hoursList = _.reduce(reqs, (sum,n) => {
        if (n.end) {
            const diff = moment(n.end).diff(moment(n.timestamp))
            const dur = moment.duration(diff).asHours()
            return [...sum, dur]
        } else {
            return sum
        }
    },[])
    const average = _.mean(hoursList)
    console.log(hoursList)

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{size}</Typography>
                        <Typography align="center" variant="h6">Trámites Totales</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{_.size(complete)}</Typography>
                        <Typography align="center" variant="h6">Trámites Atendidos</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{size - _.size(complete)}</Typography>
                        <Typography align="center" variant="h6">Trámites Pendientes</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{average.toFixed(0)} horas</Typography>
                        <Typography align="center" variant="h6">Tiempo de Atención</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head" align="center">Folio</TableCell>
                        <TableCell variant="head" align="center">Fecha</TableCell>
                        <TableCell variant="head" align="center">Tipo</TableCell>
                        <TableCell variant="head" align="center">Nombre</TableCell>
                        <TableCell variant="head" align="center">Email</TableCell>
                        <TableCell variant="head" align="center">Tiempo de Atención</TableCell>
                        <TableCell variant="head" align="center">Ver Más</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    reqs.map((item,index) => <Item key={index} item={item} />)
                }
                </TableBody>
            </Table>
        </Container>
    )
}