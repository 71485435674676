
export const areas = [
    {
        main: 'Presidencia Municipal',
        list: [
            {
                name: 'Apoyos Económicos a personas de escasos recursos',
                reqs: `<ol>
                <li>Solicitud firmada por el interesado (Dirigida al C. Presidente Municipal, al final agregar el nombre completo del solicitante y firma exactamente como aparece en la credencial de elector).</li>
                <li>Dos Copias de la credencial de elector (debe de estar vigente, legible y pertenecer al municipio de Guasave).</li>
                <li>Copia de la CURP.</li>
                <li>Estudio socioeconómico, mismo que se elabora en la dirección de educación, cultura y promoción social.</li>
                <li>Documento que acredite la petición (receta médica, análisis médico de cualquier institución excepto IMSS o ISSSTE, constancia de estudios, Convocatoria de Congresos, copia de recibos excepto de agua potable, todos deben ser con fecha reciente; en el caso de la solicitud de apoyo por gastos funerarios, se debe de anexar el estado de cuanta con fecha actualizada y Copia del Acta de defunción).</li>
                <li>Llenar formato de clabe interbancaria (es necesario que el solictante tenga una cuenta de banco a su nombre para quew ahi se le dfeposite el apoyo; el formato en el cual deben capturararse los datos se encuentra anexo en esta página, o bien, en la oficina de Presidencia Municipal se les apoya a llenarlo).</li>
                </ol>`
            },
            {
                name: 'Solicitud de Autobus',
                reqs: `<ul>
                <li>2 Solicitudes Originales, firmadas por el interesado en representación del grupo de personas que viajar, con sello y hoja membretada de la institución que respalde la petición, especificando lugar, fecha y motivo del viaje, debe ser dirigida al C. Presidente Municipal. (Al final agregar nombre completo del solicitante y firma exactamente como aparece en la credencial de elector).</li>
                <li>2 Copias de la Credencial de Elector (debe de estar vigente, legible y pertenecer al muinicipio de Guasave).</li>
                <li>2 Copias de la CURP.</li>
                <li>Lista de personas que viajar en en el autobus (nombre y firma).</li>
                <li>Número telefónico de Contacto.</li>
                </ul>`,
                notes: `<ol>
                <li>Los solicitantes aportar en el costo del diesel y el pago de casetas para la realización del viaje.</li>
                <li>Al regreso del viaje, es necesario que se entregue en la Presidencia Municipal evidencia fotográfica de las actividades realizdas durante el viaje y una carta de agradecimiento o constancia de que recibieron el servicio en tiempo y forma como fue requerido.</li>
                </ol>`
            },
            {
                name: 'Solicitud de Información en Ventanilla',
                reqs: `<p><b>Costo:&nbsp;</b><span style="font-weight: 400;">Gratuito</span><span style="font-weight: 400;"><br>
                </span><b>Horario de atención:&nbsp;</b><span style="font-weight: 400;">De 8:00 a.m. a 15:00 p.m.</span><span style="font-weight: 400;"><br>
                </span><b>Duración de trámite:&nbsp;</b><span style="font-weight: 400;">Solicitud Inmediata (A responder a más tardar hasta por 10 días por parte del sujeto obligado de acuerdo a lo estipulado en la Ley de Transparencia y Acceso a la Información Pública).</span><span style="font-weight: 400;"><br>
                </span><b>Domicilio donde se realiza:&nbsp;</b><span style="font-weight: 400;">Ave. López Mateos s/n, Col. del Bosque.</span></p><p><b>Requisitos:</b><span style="font-weight: 400;">&nbsp;Solo Presentarse en la oficinas en el domicilio antes mencionado.</span></p><p>Para cualquier duda o aclaración favor de comunicarse al teléfono 687 87 1 87 00</p>`,
                forms: [
                    {
                        name: 'Formato de Solicitud de Información',
                        link: 'https://firebasestorage.googleapis.com/v0/b/guasave-digital.appspot.com/o/SOLICITUD-DE-VENTANILLA.pdf?alt=media&token=83b14988-337b-4ba8-9e6d-d36f2f14047b'
                    }
                ]
            },
            {
                name: 'Solicitud de Información por medio de Sistema Infomex',
                reqs: `<ol>
                <li><span style="font-weight: 400;">Darse de alta como usuario en la Plataforma del Sistema Infomex en&nbsp;</span><a href="http://www.infomexsinaloa.org.mx/infomexsinaloa/Default.aspx"><span style="font-weight: 400;">http://www.infomexsinaloa.org.mx/infomexsinaloa/Default.aspx</span></a></li>
                <li><span style="font-weight: 400;">Realizar cualquier solicitud que requiera llenando lo campos pertinentes.</span></li>
                </ol>`,
                forms: [
                    {
                        name: 'Formato Solicitud de Información por medio de Sistema Infomex',
                        link: 'https://firebasestorage.googleapis.com/v0/b/guasave-digital.appspot.com/o/Solicitud%20de%20Informacio%CC%81n%20por%20medio%20de%20Sistema%20Infomex.pdf?alt=media&token=000c2640-ffe4-4865-a85a-46e70cd55553'
                    }
                ]
            },
            {
                name: 'Atención a medios de Comunicación',
                reqs: `<ol>
                    <li>Presentarse de 8:00 am a 15:00 pm</li>
                </ol>`
            },
            {
                name: 'Orientación y Asesoría para ejercer Derechos ARCO',
                reqs: `<ol>
                <li><span style="font-weight: 400;">Presentarse en las Oficinas en Horario de Atención, o bien, a través de correo electrónico oficial </span><a href="mailto:infopub@guasave.gob.mx"><span style="font-weight: 400;">infopub@guasave.gob.mx</span></a><span style="font-weight: 400;">&nbsp;</span></li>
                <li><span style="font-weight: 400;">Para cualquier duda o aclaración favor de comunicarse al teléfono 687 87 1 87 00</span></li>
                </ol>`
            }
        ]
    },
    {
        main: 'Tesorería',
        list: [
            {
                name: 'Consulta y Pago Predial',
                type: 'link',
                url: 'https://www.pagosguasave.org/ConsultarAdeudo.aspx'
            },
            {
                name: 'Impuesto sobre Adquisición de Inmuebles (ISAI)',
                reqs: `<p><strong>Costo:&nbsp;</strong>El 2% sobre la base del a Operación mas el 10%.<br>
                <strong>Horario de atención:&nbsp;</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:&nbsp;</strong>Inmediato.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>`
            },
            {
                name: 'Infracciones de Tránsito',
                reqs: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;se determina en base a un calificador, según el tipo de infracción son el número de salarios que se aplican los cuales se multiplican en base al salario vigente al momento de la infracción.</p>
                <p><strong>Horario de atención:</strong> De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite: </strong>Inmediato.<br>
                <strong>Domicilio donde se realiza:</strong> Av. López Mateos s/n, Col. Del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>
                </div>`
            },
            {
                name: 'Solvencia Fiscal',
                reqs: `<div id="articlewrap">
                <p><strong>Costo:&nbsp;</strong>$207.67.<br>
                <strong>Horario de atención:&nbsp;</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:&nbsp;</strong>Inmediato.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>
                </div>`
            },
            {
                name: 'Constancia de No Infracción',
                reqs: `<div id="articlewrap">
                <p><strong>Costo: </strong>$50.00.<br>
                <strong>Horario de atención:&nbsp;</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:&nbsp;</strong>Inmediato.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>
                </div>`
            }
        ]
    },
    {
        main: 'Secretaría del Ayuntamiento',
        list: [
            {
                name: 'Trámites del Modulo Enlace RAN',
                reqs: `<div class="entry-content" itemprop="text">
                <p><strong>T</strong><strong>R</strong><strong>A</strong><strong>M</strong><strong>I</strong><strong>T</strong><strong>ES QUE SE LLEVAN ACABO ANTE LA OFICINA DEL ENALCE RAN.</strong></p>
                <ul>
                <li>RECONOCIMIENTO DE AVECINDADOS ANTE EL EJIDO.</li>
                </ul>
                <ul>
                <li>REPOSICION DE CERTIFICADO PARCELARIO.</li>
                </ul>
                <ul>
                <li>TRASLADO DE DERECHO.</li>
                </ul>
                <ul>
                <li>ENAJENACION DE DERECHOS PARCELARIOS.</li>
                </ul>
                <ul>
                <li>VIGENCIAS DE DERECHO.</li>
                </ul>
                <ul>
                <li>INSCRIPCIONES DE S.P.R. (SOCIEDAD DE PRODUCCIÓN RURAL).</li>
                </ul>
                <p><strong>REQUISITOS DE ACUERDO AL TRÁMITE QUE DESEEN REALIZAR ANTE EL ENLACE.</strong></p>
                <p>REQUISITOS PARA RECONOCIMIENTO DE AVECINDADO EN EL EJIDO O COMUNIDAD:</p>
                <ul>
                <li>CONVOCATORIA(S) ACTAS DE NO VERIFICATIVO (ORIGINAL).</li>
                <li>ACTA DE ASAMBLEA (ORIGINAL).</li>
                <li>ACTA DE NACIMIENTO DEL RECONOCIDO COMO AVECINDADO (ORIGINAL).</li>
                <li>ACREDITAMIENTO DE LA RESIDENCIA DE 1 AÑO O MAS (MANIFESTACION DEL COMISARIADO EJIDAL, ORIGINAL).</li>
                <li>CURP DEL RECONOCIDO.</li>
                <li>IDETIFICACION OFICIAL VIGENTE (ORIGINAL Y COPIA). REQUISITOS PARA REPOSICIÓN DE CERTIFICADO PARCELARIO:</li>
                <li>CURP DEL SOLICITANTE.</li>
                <li>IDETIFICACION OFICIAL VIGENTE (ORIGINAL Y COPIA).</li>
                <li>ACTA DE NACIMIENTO (ORIGINAL Y COPIA).</li>
                <li>CONSTANCIA DE EXTRAVIO ANTE EL MP (MINISTERIO PÚBLICO).</li>
                <li>COPIA DEL CERTIFICADO PARCELARIO EXTRAVIADO.</li>
                </ul>
                <p>REQUISITOS PARA TRASLADO DE DERECHOS AGRARIOS POR LA MUERTE DEL TITULAR:</p>
                <ul>
                <li>COMPROBANTE DE PAGO DE DERECHOS (ORIGINAL).</li>
                <li>ACTA DE DEFUNSION DEL TITULAR DEL DERECHO (ORIGINAL).</li>
                <li>ACTA DE NACIMIENTO DEL SUCESOR (ORIGINAL).</li>
                <li>IDENTIFICACION OFICIAL VIGENTE DEL SOLICITANTE (ORIGINAL Y COPIA).</li>
                <li>ACTA DE APERTURA DEL SOBRE (ORIGINAL).</li>
                <li>CURP DEL SUCESOR PREFERENTE.</li>
                </ul>
                <p>REQUISITOS PARA UNA ENAJENACION DERECHOS PARCELARIOS:</p>
                <ul>
                <li>COMPROBANTE DE PAGO DE DERECHOS.</li>
                <li>CONTRATO FIRMADO DE LAS PARTES ANTE DOS TESTIGOS, RATIFICADO POR NOTARIO PUBLICO.</li>
                <li>CONSTANCIA DE NOTIFICACION DEL DERECHO DEL TANTO.</li>
                <li>EN SU CASO, CONSTANCIA DE RENUNCIA EXPRESA AL DERECHO DEL TANTO, ANTE DOS TESTIGOS.</li>
                <li>CERTIFICADOS PARCELARIOS (ORIGINAL).</li>
                <li>DOCUMENTO DE AVISO AL COMISARIO EJIDAL O DE BIENES COMUNALES (ORIGINAL).</li>
                <li>CURP DEL ENAJENANTE Y ADQUIERIENTE.</li>
                <li>IDENTIFICACION OFICIAL VIGENTE DEL SOLICITANTE (ORIGINAL Y COPIA).</li>
                </ul>
                <p>ACTA DE NACIMIENTO DEL SOLICITANTE (ORIGINAL Y COPIA).</p>
                <p>REQUISITOS PARA VIGENCIAS DE DERECHO:</p>
                <ul>
                <li>COMPROBANTE DE PAGO DE DERECHOS (ORIGINAL).</li>
                <li>ACTA DE NACIMIENTO (ORIGINAL Y COPIA).</li>
                <li>CURP ACTUAL.</li>
                <li>IDENTIFICACION OFICIAL VIGENTE.</li>
                </ul>
                </div>`
            },
            {
                name: 'Programas de la Delegación Municipal de Atención a menores y Adolescentes',
                reqs: `<div id="articlewrap">
                <ol>
                <li><strong>ATENCIÓN INTEGRAL DE DETENIDO.</strong></li>
                </ol>
                <ul>
                <li>Entrevista con el Juez de Barandilla en Turno.</li>
                <li>Primer Contacto con el Menor.</li>
                <li>Dar Aviso a los Padres o Tutores.</li>
                <li>Se brinda asesoría cívica y Moral al menor.</li>
                <li>Se brinda asesoría psicológica y legal al menor y a su familia</li>
                <li>Se agenda cita de Control y seguimiento.</li>
                <li>En caso necesario se canaliza a las instituciones correspondientes.</li>
                </ul>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; 2. CONTROL Y SEGUIMIENTO DE MENORES Y SUS FAMILIAS.</strong></p>
                <ul>
                <li>SE DETECTA AL MENOR, YA SEA POR DETENCIÓN O POR SOLICITUD FAMILIAR.</li>
                <li>SE REALIZA LA ENTREVISTA DE PRIMER CONTACTO.</li>
                <li>SE ELABORA EL ARCHIVO CORRESPONDIENTE CON LOS DATOS DEL CASO.</li>
                <li>SE PROCEDA A REALIZAR LA PRIMERA SESIÓN QUE GENERALMENTE ES EMERGENTE.</li>
                <li>SE ABRE AGENDA PARA PROGRAMAR CITAS DE CONTROL.</li>
                <li>SE DA EL SEGUIMIENTO CORRESPONDIENTE.</li>
                </ul>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; 3. ATENCIÓN EXTERNA A MENORES INTERNADOS EN CENTROS DE DESINTOXICACIÓN.</strong></p>
                <ul>
                <li>Se elabora una Agenda de trabajo.</li>
                <li>Se realizan las visitas</li>
                <li>Se llevan a cabo sesiones con el Departamento de trabajo social.</li>
                </ul>
                <p><strong>&nbsp; &nbsp; &nbsp; &nbsp; 4. GESTORÍA</strong></p>
                <ul>
                <li>Se atienden las solicitudes recibidas &nbsp;en la Delegación</li>
                <li>Se gestionan según sea el caso</li>
                <li>Becas de Capacitación para el trabajo</li>
                <li>Apoyo para internamiento en algún Centro de Desintoxicación.</li>
                </ul>
                <p><strong>NOTA: </strong>LA POBLACIÓN BENEFICIADA CON &nbsp;LAS ATENCIONES BRINDADAS SON LOS MENORES CON PROBLEMAS DE CONDUCTAS ANTISOCIALES Y SUS FAMILIAS QUE ASÍ LO REQUIERAN.</p>
                </div>`
            },
            {
                name: 'Expedición de Constancias de No Infracción (Coord. de Atención a menores y adolescentes)',
                reqs: `<ul>
                <li>Acta de Nacimiento</li>
                <li>Curp</li>
                <li>Comprobante de Domicilio</li>
                <li>Identificación con Fotografía</li>
                </ul>`
            },
            {
                name: 'PROFECO',
                reqs: `<div id="articlewrap">
                <p><u><strong>RECEPCIÓN DE QUEJA</strong></u></p>
                <p><strong>Costo:</strong> Gratuito</p>
                <p><strong>Requisitos:</strong>&nbsp;2 copias de IFE, factura y garantía.</p>
                <p><strong>Respuestas:</strong>&nbsp;30 días.</p>
                <p><strong>Domicilio:</strong>&nbsp;Calle Pino Suárez s/n entre J. Cmacho Téllez y Ave. Lázaro Cárdenas frente a las nuevas oficinas de Salud Municipal.</p>
                <p>&nbsp;</p>
                <p><u><strong>CALIBRACION DE BASCULA HASTA 100 kgs.</strong></u></p>
                <p><strong>Costo:</strong>&nbsp;$221.00</p>
                <p><strong>Requisitos:</strong>&nbsp;1 copia de IFE, RFC y poder notarial.</p>
                <p><strong>Respuestas:</strong>&nbsp;1 Semana.</p>
                <p><strong>Domicilio:</strong>&nbsp;Calle Pino Suárez s/n entre J. Cmacho Téllez y Ave. Lázaro Cárdenas frente a las nuevas oficinas de Salud Municipal.</p>
                <p>&nbsp;</p>
                <p><u><strong>CALIBRACION DE BASCULA HASTA 5000 kgs.</strong></u></p>
                <p><strong>Costo:</strong>&nbsp;$340.00</p>
                <p><strong>Requisitos:</strong>&nbsp;1 copia de IFE, RFC y poder notarial.</p>
                <p><strong>Respuestas:</strong>&nbsp;1 Semana.</p>
                <p><strong>Domicilio:</strong>&nbsp;Calle Pino Suárez s/n entre J. Cmacho Téllez y Ave. Lázaro Cárdenas frente a las nuevas oficinas de Salud Municipal.</p>
                </div>`
            },
            {
                name: 'Modulo de Infonavit',
                reqs: `<div id="articlewrap">
                <p style="text-align: justify;"><strong>Tipo de Trámite: </strong>Le informamos a la ciudadanía de Guasave, que contamos con una oficina de enlace INFONAVIT para asesorar a todos los derechohabientes que estén laborando con seguro social y su patrón este haciendo la aportación correspondiente pueda realizar en nuestras oficinas los siguientes trámites:</p>
                <ul style="text-align: justify;">
                <li><strong>DIRECCIÓN</strong><strong>: </strong>Instalaciones Escuela Preparatoria General Francisco J. Mújica.<strong>&nbsp;</strong>Calle: Miguel Hidalgo Ayuntamiento 92. A espaldas de escuela ICATSIN.</li>
                </ul>
                <ul style="text-align: justify;">
                <li><strong>NÚMERO OFICINA </strong><strong>:</strong> RICARDO PEÑA <strong>667-795-9873</strong></li>
                </ul>
                <p style="text-align: justify;">-APERTURA CUENTA INFONAVIT</p>
                <p style="text-align: justify;">-ELABORACIÓN DE EXPEDIENTES PARA SOLICITAR CRÉDITO INFONAVIT, VENTA O COMPRA</p>
                <p style="text-align: justify;">– PRECALIFICACIÓN A CUALQUIER LÍNEA DE CRÉDITO</p>
                <p style="text-align: justify;">-ASESORÍA EN LOS DIFERENTES TIPOS DE CRÉDITO (INFONAVIT, MEJORAVIT, CRÉDITO SEGURO)</p>
                <p style="text-align: justify;">-INSCRIPCIÓN CRÉDITO MEJORAVIT</p>
                <p style="text-align: justify;">-ESTADOS DE CUENTAS (SALDOS Y MOVIMIENTOS)</p>
                <p style="text-align: justify;">-SOLICITUD DEVOLUCIÓN SALDO SUBCUENTA DE VIVIENDA</p>
                <p style="text-align: justify;">-AVISOS DE SUSPENSIÓN, RETENCIÓN Y MODIFICACIÓN DE DESCUENTOS</p>
                <p style="text-align: justify;">– ACTIVACIÓN DE PROGRAMAS INFONAVIT</p>
                <p style="text-align: justify;">-SOLICITUD DE DESCUENTO POR LIQUIDACIÓN ANTICIPADA</p>
                <p style="text-align: justify;">-DEVOLUCIÓN DE PAGOS EN EXCESO</p>
                <p style="text-align: justify;">-CITAS A CENTROS DE SERVICIOS INFONAVIT (LOS MOCHIS, CULIACÁN)</p>
                <p style="text-align: justify;">-ASISTENCIA DIRECTA INFONATEL <strong>01-800-008-3900</strong> (TENER NSS o # CRÉDITO)</p>
                <p style="text-align: justify;">El propósito que tiene este Módulo de Enlace de INFONAVIT es brindarle al derecho-habiente y al acreditado una asesoría personalizada, agilizarle los servicios que pueden realizar ante la Institución Infonavit , sin la necesidad de acudir a la ciudad de los Mochis o Culiacán , asesorar ante cualquier trámite y direccionar antes cualquiera de los créditos disponibles para que así puedan adquirir una vivienda digna y sustentable.</p>
                </div>`
            },
            {
                name: 'Tribunal Municipal de Conciliación y Arbitraje',
                reqs: `<div id="articlewrap">
                <p><strong>Tipo de Trámite: </strong>Corresponde a los Tribunales el conocimiento y resolución de los conflictos que suscriben entre los trabajadores y las entidades públicas municipales, o solo entre aquellos, con motivo de las relaciones de trabajo o de los hechos íntimamente relacionados con ellas. Según Titulo 8vo. Capitulo I, Artículo 65 de la Ley de los Trabajadores al Servicio &nbsp;de los Municipios del Estado de Sinaloa.</p>
                <p>El Procedimiento y la resolución hasta dictar el laudo firme en los expedientes en proceso de acuerdo a la Ley de los trabajadores al Servicio de los Municipios del Estado de Sinaloa.</p>
                <p><strong>Teléfono:</strong> 7211997</p>
                <p><strong>Domicilio donde se realiza:</strong> Lazaro Cárdenas casi esquina con Calle Acacias s/n Col. del Bosque.</p>
                <p><strong>Personas Beneficiadas:</strong> Trabajadores del Municipio.</p>
                <p><strong>Costo:</strong> Gratuito</p>
                </div>`
            },
            {
                name: 'Servicios de Expediciones de Constancias en la Coord. de Sindicaturas y Comisarias',
                notes: `<div id="articlewrap">
                <p><u><strong>CARTAS Y CONSTANCIAS</strong></u></p>
                <p>1.- CARTA DE BUENA CONDUCTA</p>
                <p>2.- CARTA DE RECOMENDACIÓN</p>
                <p>3.- CARTA DE TRABAJO</p>
                <p>4.- CONSTANCIA DE DEPENDENCIA ECONOMICA</p>
                <p>5.- CONSTANCIA DE INGRESOS</p>
                <p>6.- CONSTANCIA DE AVECINDADO</p>
                <p>7.- CONSTANCIA DE BAJO RECURSOS</p>
                <p>8.- CONSTANCIA DE COMPROBANTE DE INGRESOS</p>
                <p>9.- CONSTANCIA DE MADRE SOLTERA</p>
                <p>10.- CONSTANCIA DE MENOR DE EDAD</p>
                <p>11.- CONSTANCIA DE PROPIEDAD DE TERRENO</p>
                <p>12.- CONSTANCIA DE RESIDENCIA CON FOTO</p>
                <p>13.- CONSTANCIA DE RESIDENCIA</p>
                <p>14.- CONSTANCIA DE UNICO LIBRE</p>
                <p>15.- ALTA DE PROSPERA</p>
                <p>16.- BAJA DE PROSPERA</p>
                <p>17.- CAMBIO DE DOMICILIO DE PROSPERA</p>
                </div>`
            },
            {
                name: 'Solicitud de Registros para Síndicos y Comisarios',
                notes: `
                <div id="articlewrap">
                <p align="center"><strong>&nbsp; &nbsp; &nbsp; &nbsp; SOLICITUD DE REGISTRO DIRIGIDA A LA COMISIÓN</strong></p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ORGANIZADORA DEL PLEBISCITO.</p>
                <p>I.- CURRICULUM</p>
                <p>II.- PLAN DE TRABAJO</p>
                <p>III.- ACTA DE NACIMIENTO (ORIGINAL)</p>
                <p>IV.- CONSTANCIA DE RESIDENCIA</p>
                <p>(EXPEDIDA POR LA SINDICATURA, LA COMISARÍA</p>
                <p>U OFICIALÍA MAYOR)</p>
                <p>V.- CONSTANCIA DE NO ANTECEDENTES PENALES</p>
                <p>(EXPEDIDA EN LA PROCURADURÍA DE LOS MOCHIS)</p>
                <p>VI.-&nbsp;&nbsp; COPIA DE CREDENCIAL DE ELECTOR</p>
                <p>VII.-&nbsp;&nbsp; 4 (CUATRO) FOTOGRAFÍAS TAMAÑO CREDENCIAL</p>
                <p>(EN BLANCO Y NEGRO).</p>
                <p><strong>VIII.- ACOMPAÑAR CON 100 FIRMAS EN FORMATO, QUE CONTENGA:</strong></p>
                <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; NOMBRE, DOMICILIO Y FOLIO DE CREDENCIAL DE ELECTOR.</strong></p>
                <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (PARA LAS 12 SINDICATURAS Y LA COMISARÍA DE</strong></p>
                <p><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GABRIEL LEYVA SOLANO).</strong></p>
                <p>ESTE FORMATO SE LES ENTREGARÁ EN LA DIRECCIÓN JURÍDICA DEL HONORABLE AYUNTAMIENTO.</p>
                <p><strong><u>NOTA</u>:&nbsp;ESTE TRAMITE ES GRATUITO.</strong></p>
                <p><strong>DOM: AV. LÁZARO CÁRDENAS</strong></p>
                <p><strong>COLONIA DEL BOSQUE S/N</strong></p>
                <p><strong>GUASAVE, SINALOA</strong></p>
                <p><strong>TEL: (687) 1 87 00 EXT: 185</strong></p>
                </div>
                `
            },
            {
                name: 'Requisitos para Solicitar Vivienda',
                reqs: `<div id="articlewrap">
                <p>· Copia de identificación oficial con fotografía (de todos los integrantes mayores de edad)</p>
                <p>· Copia de acta de nacimiento (todos los integrantes del hogar)</p>
                <p>· Copia de la CURP (todos los integrantes del hogar)</p>
                <p>· Comprobante de domicilio (agua, luz, etc. (de preferencia de agua)</p>
                <p>· &nbsp;Escrito bajo protesta de decir verdad de no haber recibido subsidio federal alguno</p>
                <p>· &nbsp;Carta de posesión de terreno, o copia de escritura</p>
                <p><strong>Nota: En zonas rurales la carta debe ser expedida por el síndico municipal o comisario ejidal</strong></p>
                <p>· &nbsp;Comprobante de residencia: expedida por síndico municipal o comisario ejidal</p>
                <p>· &nbsp;Comprobante de ingresos.</p>
                <p><strong><u>NOTA</u>: ESTE TRAMITE ES GRATUITO.</strong></p>
                <p><strong>DOM: AV. LÁZARO CÁRDENAS</strong></p>
                <p><strong>COLONIA DEL BOSQUE S/N</strong></p>
                <p><strong>GUASAVE, SINALOA</strong></p>
                <p><strong>TEL: (687) 1 87 00 EXT: 185</strong></p>
                </div>`
            },
            {
                name: 'Requisitos para la Revalidación de Licencia de Alcoholes',
                reqs: `<div id="articlewrap">
                <p>· Solicitud por escrita dirigida al H. Ayuntamiento</p>
                <p>· Copia de Constancia sanitaria&nbsp; Actualizada presentada con la solicitud de licencia siempre y cuando la misma no haya sido revocada.</p>
                <p>· Constancia de no adeudo por concepto de multas impuestas por la Dirección de Inspección y Normatividad.</p>
                <p>&nbsp;</p>
                <p><strong>Que les otorgan en la Dirección de Alcoholes del Estado.</strong></p>
                <p>· &nbsp;Copia de la licencia 2014</p>
                </div>`
            },
            {
                name: 'Requisitos para la Expedición de Licencias (Gobierno del Estado) y carta de opinión favorable (Ayuntamiento)',
                reqs: `<div id="articlewrap">
                <p>– &nbsp;PRESENTAR SOLICITUD ESCRITA AL AYUNTAMIENTO QUE CORRESPONDA.</p>
                <p>– &nbsp;COPIA CERTIFICADA DEL ACTA DE NACIMIENTO SI SE TRATA DE PERSONA FISICA, O COPIA CERTIFICADA DE ACTA CONSTITUTIVA SI SE TRATA DE PERSONAS MORALES.</p>
                <p>– &nbsp;CROQUIS O PLANO DONDE SE INDIQUE EN FORMA CLARA Y PRECISA, LA UBICACION DEL LOCAL EN QUE SE PRETENDE ESTABLECER EL NEGOCIO, SEÑALANDO LA DISTANCIA A LA ESQUINA MAS PROXIMA.</p>
                <p>– &nbsp;CONSTANCIA DE LA INVERSION REALIZADA O PROYECTO DE INVERSION A REALIZAR.</p>
                <p>– &nbsp;CONSTANCIA EXPEDIDA POR LA AUTORIDAD SANITARIA DE LA JURISDICCION, QUE ACREDITE QUE EL LOCAL REUNE LOS REQUISITOS SANITARIOS VIGENTES.</p>
                <p>– &nbsp;CARTA DE NO ANTECEDENTES PENALES EXPEDIDA POR LA PROCURADURIA GENERAL DE JUSTICIA DEL ESTADO DE SINALOA, TRATANDOSE DE PERSONAS FISICAS.</p>
                <p>– &nbsp;CONSTANCIA QUE ACREDITE QUE EL SOLICITANTE NO TIENE ADEUDOS FISCALES, ESTATALES O MUNICIPALES.</p>
                <p>– &nbsp;FOTOGRAFIAS RECIENTES DE LAS INSTALACIONES, DOS EXTERIORES Y TRES INTERIORES.</p>
                <p>– &nbsp;COPIA DEL REGISTRO FEDERAL DE CONTRIBUYENTES.</p>
                <p>– &nbsp;LA ANUENCIA MAYORITARIA DE LOS VECINOS CON DOMICILIO DENTRO DE UN RADIO DE 150 MTS DEL LUGAR DE HUBICACION DONDE SE PRETENDE ESTABLECER EL GIRO DE CERVECERIA, CANTINA, BAR, CABARET, CENTRO NOCTURNO O DEPOSITO DE CERVEZA; PARA LOS DEMAS GIROS LOS VECINOS CON DOMICILIO EN UN RADIO DE ACCION DE 75 MTS; LA ANUENCIA DEBERA SER POR ESCRITO Y SEÑALANDO EL TIPO DE GIRO PARA EL QUE SE OTORGA.</p>
                <p>– &nbsp;CONSTANCIA DE USO Y APROVECHAMIENTO DEL SUELO.</p>
                <p>– &nbsp;DOS CARTAS DE RECOMENDACIÓN QUE ACREDITEN SU SOLVENCIA MORAL Y ECONOMICA.</p>
                <p>– &nbsp;CERTIFICADO DE UBICACION EXPEDIDO POR LA PRESIDENCIA MUNICPAL EN EL QUE SE ESPECIFIQUE QUE EL ESTABLECIMIENTO NO CONTRAVIENE LO DISPUESTO EN EL ART. 45, FRACCION I, DE LA PRESENTE LEY.</p>
                </div>`
            },
            {
                name: 'Requisitos para Cambio de Domicilio',
                reqs: `
                <div id="articlewrap">
                <p><strong>TODAS LAS SOLICITUDES DEBERAN SER APROBADAS POR LA MAYORIA DE LOS INTEGRANTES DEL AYUNTAMIENTO.</strong></p>
                <p>– &nbsp;PRESENTAR SOLICITUD ANTE LA DIRECCION DE INSPECION Y NORMATIVIDAD Y AYUNTAMIENTO, SEÑALANDO EN ESTA LOS MOTIVOS POR LOS CUALES PRESENTA DICHA SOLICITUD.</p>
                <p>– &nbsp;CROQUIS DE UBICACIÓN DEL DOMICILIO SOLICITADO, SEÑALANDO DE MANERA CLARA Y PRESISA EL MISMO, INDICANDO LA DISTANCIA A LA ESQUINA MAS PROXIMA.</p>
                <p>– &nbsp;CONSTANCIA SANITARIA EXPEDIDA POR LA AUTORIDAD SANITARIA DE LA JURIDICION.</p>
                <p>– &nbsp;CONSTANCIA DE NO ADEUDO FISCAL ESTATAL Y MUNICIPAL.</p>
                <p>– &nbsp;FOTOGRAFIAS RECIENTES DE LAS INSTALACIONES (3) INTERIOR&nbsp; Y EXTERIOR (2).</p>
                <p>– &nbsp;FIRMA MAYORITARIA DE LOS VECINOS DENTRO DE UN RADIO DE 150 MTS PARA LOS GIROS DE CERVECERIA, CANTINA, BAR,CABARET, CENTRO NOCTURNO O DEPOSITO DE CERVEZA;&nbsp; PARA RESTO DE LOS GIROS LA FIRMA DE LOS VECINOS SERAN LOS UBICADOS&nbsp; DENTRO DE UN RADIO DE 75 MTS DEL DOMICILIO.</p>
                <p>– &nbsp;CONSTANCIA DE USO Y APROVECHAMIENTO DE SUELO.</p>
                <p>– &nbsp;CERTIFICADO DE UBICACIÓN EXPEDIDA POR LA PRESIDENCIA MUNCIIPAL EN EL QUE SE EXPECIFIQUE QUE EL ESTABLECIMIENTO NO CONTRA VIENE LO DISPUESTO EN EL ART. 45 FRACC. 1 DE LA LEY EN LA MATERIA.</p>
                <p>– &nbsp;CONSTANCIA DE INVERSION REALIZADA O PROYECTO DE INVERSION A REALIZAR.</p>
                </div>
                `
            },
            {
                name: 'Requisitos para solicitar la cartilla del servicio militar nacional',
                reqs: `<ul>
                <li>Acta de nacimiento Original y Copia.</li>
                <li>4 fotografías blanco y negro, tamaño credencial, recientes (que no sean instantáneas, sin anteojos, sin patilla, bien rasurado, peinado, sin arete, cadena y con camisa de cuello clara o Blanca).</li>
                <li>Constancia de estudios Original y Copia.</li>
                <li>Identificación con fotografía&nbsp;Original y Copia.</li>
                <li>Constancia con residencia (en caso de haber nacido fuera del municipio).</li>
                <li>Comprobante de Domicilio&nbsp;Original y Copia.</li>
                <li>CURP Reciente&nbsp;Original y Copia.</li>
                </ul>`
            },
            {
                name: 'Requisitos para duplicados, correcciones de la cartilla del Servicio Militar Nacional y constancias para ciudadanos mayores de más de 40 años',
                notes: `<div id="articlewrap">
                <p><strong>Requisitos</strong></p>
                <ul>
                <li>Acta de Nacimiento.</li>
                <li>3 Fotografías a Color con fondo blanco.</li>
                <li>Número de Matrícula.</li>
                <li>Efectuar pago en el banco con forma 5 de la Secretaría de Hacienda y Crédito Público (SHCP).</li>
                </ul>
                <p><strong>Costos:</strong></p>
                <ul>
                <li>Duplicados $125.</li>
                <li>Correcciones $125.</li>
                <li>Constancias $280.</li>
                </ul>
                <p><strong>Horario de atención:</strong> De Lunes a Viernes a las 10 a.m.</p>
                <p><strong>Lugar: </strong>Acudir a la IX Zona Militar en Culiacán, Sin.</p>
                <p><strong>Tiempo de respuesta: </strong>5 Días hábiles.</p>
                </div>`
            },
            {
                name: 'Tarifas para bailes y fiestas familiares en el Municipio',
                notes: `<div id="articlewrap">
                <p><strong>Cabecera Municipal</strong></p>
                <p><em>Baile de especulación</em> $3,400.00</p>
                <p><strong>Sindicaturas</strong></p>
                <p><em>Baile de especulación</em> $2,850.00</p>
                <p><strong>Comisarías</strong></p>
                <p><em>Baile de especulación</em> $1,700.00</p>
                <p><strong>Nochadas disco</strong></p>
                <p><em>Sindicaturas y Comisarías</em> $590.00</p>
                <p><strong>Fiestas familiares</strong></p>
                <p><em>Salón para bailes</em> $300.00</p>
                <p><em>Casa particular</em> $100.00</p>
                <p><strong>Sindicaturas y Comisarías</strong></p>
                <p><em>Salón para bailes</em> $200.00</p>
                <p><em>Casa particular</em> $100.00</p>
                <p><strong>Requisitos:</strong></p>
                <p>Cuando es un baile en una Comunidad, se requiere la anuencia del Síndico y del Comité de participación ciudadana; cuando es una fiesta particular se requiere la anuencia de la autoridad o propietario del lugar.</p>
                <p><strong>Horario de atención: </strong>De 8 a 5 pm de lunes a viernes.</p>
                <p><strong>Tiempo de respuesta: </strong>3 dias hábiles.</p>
                <p><strong>Ubicación de oficinas: </strong>Dirección de alcoholes al interior de la explanada del H. Ayuntamiento de Guasave.</p>
                </div>`
            }
        ]
    },
    {
        main: 'Relaciones Exteriores',
        list: [
            {
                name: 'Localización de personas para Repatriarlos.',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Trámite:</strong>&nbsp;Localización y Ayuda de personas vulnerables para repatriarlos, asegurándonos que el proceso se realice de forma segura, digna y humana.</p>
                <p><strong>Costo:</strong>&nbsp;Gratuito.<br>
                <strong>Horario de atención:&nbsp;</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Presentarse el interesado con Identificación.</li>
                <li>Presentar Fotografía de la Persona a repatriar y documentacion de identifIcación.</li>
                </ul>
                </div>`
            },
            {
                name: 'Traslado de Restos Funerarios',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Trámite:</strong>&nbsp;Traslado de Restos y/o Cenizas para brindar una sepultura digna y cercana de los suyos.</p>
                <p><strong>Costo:</strong>&nbsp;Gratuito.<br>
                <strong>Horario de atención:&nbsp;</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Presentarse el interesado con Identificación.</li>
                <li>Presentar Fotografía de la Persona Fenecida y documentacion de identifIcación.</li>
                </ul>
                </div>`
            },
            {
                name: 'Información',
                notes: `<div id="articlewrap">
                <p><strong>Costo: </strong>Gratuito.<br>
                <strong>Horario de atención: </strong>De 8:00 a.m. a 3:00 p.m.<br>
                <strong>Duración de trámite: </strong>Inmediato.<br>
                <strong>Domicilio donde se realiza: </strong>Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong> Ninguno.</p>
                </div>`
            },
            {
                name: 'Trámite de pasaporte',
                notes: `<div id="articlewrap">
                <p><strong>Costos:</strong></p>
                <ul>
                <li><strong>1 año / $625.00</strong> (Mexicanos menores de 3 años).</li>
                <li><strong>3 años </strong><strong>/ </strong><strong>$1,300.00</strong> (Mexicanos mayores de 3 años y menores de 18 años / Mexicanos mayores de 18 años).</li>
                <li><strong>6 años </strong><strong>/ </strong><strong>$1,790.00</strong> (Mexicanos mayores de 3 años y menores de 18 años / Mexicanos mayores de 18 años).</li>
                <li><strong>10 años </strong><strong>/ </strong><strong>$2,750.00</strong> (Mexicanos mayores de 18 años).</li>
                </ul>
                <p><strong>Las personas con Capacidades Diferentes y/o Especiales, trabajadores agrícolas temporales y personas mayores de 60 Años&nbsp;tienen el Beneficio del 50% de Descuento.</strong></p>
                <p>Pago adicional al costo del pasaporte por brindar el servicio es de <strong>$200</strong> por trámite en todas las modalidades.</p>
                <p><strong>Teléfono:</strong> 01 800 5000 773</p>
                <p><strong>Horario de atención:</strong> De Lunes a Viernes de 8:00 a.m. a 3:00 p.m.</p>
                <p><strong>Duración de trámite:</strong> De 5 días en un trámite Normal y hasta 20 días en trámites de modificación de datos, extravío y/o Robo.</p>
                <p><strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga: </strong>En cualquier banco y en tesorería (Cajas).</p>
                <p><strong>Requisitos: </strong></p>
                <ul>
                <li>Presentarse personalmente.</li>
                <li>Acta de Nacimiento RECIENTE (General).</li>
                <li>Fotos (Tamaño Pasaporte frente y orejas descubiertas sin accesorios, expedición menos de 30 días).</li>
                <li>Credencial de Elector VIGENTE, Cédula profesional o cartilla militar liberada.</li>
                <li>Constancia Escolar y Acta de Nacimiento (Menores de 18 años sólo en caso de trámite por primera vez).</li>
                <li>Carta de conocimiento (Menores de 6 años, sólo en caso de trámite por primera vez, acudir para que le den el formato de la carta).</li>
                <li>Identificación de padres (Menores de 18 años).</li>
                <li>CURP.</li>
                </ul>
                <p><strong>Formatos a LLenar:&nbsp;</strong></p>
                <p><strong>CITAS:</strong> 01 800 5000 773 ó en <strong>www.sre.gob.mx</strong> (sólo en Delegación, en oficinas de enlace no se necesita previa cita).</p>
                </div>`
            },
            {
                name: 'Entrega de Valores Confiscados en el Extranjero.',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Trámite:</strong>&nbsp;Entrega de Valores y/o pertenencias a las personas que les fueron confiscadas al momento de su detención en el Extranjero.</p>
                <p><strong>Costo:</strong>&nbsp;Gratuito.<br>
                <strong>Horario de atención:&nbsp;</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Presentarse el interesado con Identificación.</li>
                <li>Presentar Fotografía de la Persona detenida y documentacion de identifIcación.</li>
                </ul>
                </div>`
            },
            {
                name: 'Localización de personas extraviadas',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Trámite:</strong>&nbsp;Localizar personas extraviadas en su Intento de cruzar la frontera hacia Estados Unidos con la colaboración de los Consulados Mexicanos establecidos.</p>
                <p><strong>Costo:</strong> Gratuito.<br>
                <strong>Horario de atención: </strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong> Variable.<br>
                <strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Presentarse el interesado con Identificación.</li>
                <li>Presentar Fotografía de la Persona Extraviada y documentacion de identifIcación.</li>
                </ul>
                </div>`
            },
            {
                name: 'Asistencia y protección consular',
                notes: `<div id="articlewrap">
                <p><strong>Costo: </strong>Gratuito.<br>
                <strong>Horario de atención:</strong> De Lunes a Viernes de 8:00 a.m. a 3:00 p.m.<br>
                <strong>Duración de trámite:</strong> El tiempo necesario según el trámite.<br>
                <strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Acudir a la oficina de enlace.</li>
                </ul>
                </div>`
            }
        ]
    },
    {
        main: 'Oficialía Mayor',
        list: [
            {
                name: 'Permisos de Eventos y Espectáculos',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;$500.00.<br>
                <strong>Horario de atención:</strong>&nbsp;De Lunes a Viernes de 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Inmediato.<br>
                <strong>Domicilio donde se realiza:</strong>&nbsp;Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Original y Copia de Credencial de Elector.</li>
                <li>Solicitud de Permiso por escrito.</li>
                <li>Original y Copia del Permiso del Propietario del Terreno.</li>
                <li>Pago del Permiso hecho en Tesorería.</li>
                <li>Original y Copia de Póliza del Seguro.</li>
                <li>Depósito de Fianza.</li>
                <li>4 Fotografías tamaño infantil.</li>
                <li>Cartas de Anuencia.</li>
                </ul>
                <p><strong>Responsable:&nbsp;</strong>Karina del Rosario Saracho Meza.</p>
                <p><strong>Oficina Receptora:&nbsp;</strong>Vía Pública.</p>
                </div>`
            },
            {
                name: 'Venta de Terrenos',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;Se determina por metro cuadrado.<br>
                <strong>Horario de atención:</strong>&nbsp;De Lunes a Viernes de 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Oficina Receptora:&nbsp;</strong>Bienes Municipales.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Copia de Credencial de Elector.</li>
                <li>Croquis del terreno</li>
                <li>Comprobante de domicilio</li>
                <li>CURP</li>
                </ul>
                <p><strong>Responsable: </strong>Rigoberto Camacho Camargo.</p>
                <p><strong>Teléfono:&nbsp;</strong>87- 2-86-57.</p>
                <p><strong>Lugar donde se paga:&nbsp;</strong>Tesorería Municipal.</p>
                </div>`
            },
            {
                name: 'Expedición de Títulos de Propiedad',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;$1,267.35.<br>
                <strong>Horario de atención:</strong>&nbsp;De Lunes a Viernes de 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Oficina Receptora:&nbsp;</strong>Bienes Municipales.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Croquis.</li>
                <li>Carta de No Inscripción</li>
                <li>Carta de Posesión</li>
                <li>Avalúo Catastral</li>
                <li>Copia de Credencial de Elector</li>
                <li>Comprobante de Domicilio</li>
                <li>CURP</li>
                </ul>
                <p><strong>Responsable:&nbsp;</strong>Rigoberto Camacho Camargo.</p>
                <p><strong>Teléfono:&nbsp;</strong>87-2-86-57.</p>
                <p><strong>Lugar donde se paga:&nbsp;</strong>Tesorería Municipal.</p>
                </div>`
            },
            {
                name: 'Gestoría',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;Gratuito.<br>
                <strong>Horario de atención:</strong>&nbsp;De Lunes a Viernes de 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Oficina Receptora:&nbsp;</strong>Coordinación de Colonias.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Presentación de la solicitud por escrito.</li>
                </ul>
                <p><strong>Responsable:&nbsp;</strong>Irvin Alejandro Loredo Sandoval.</p>
                </div>`
            },
            {
                name: 'CURP (Cédula Única de Registro Poblacional)',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;Gratuito.<br>
                <strong>Horario de atención:</strong>&nbsp;De Lunes a Viernes de 9:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;3 días.<br>
                <strong>Domicilio donde se realiza:&nbsp;</strong>Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Oficina Receptora:&nbsp;</strong>Oficialía Mayor.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Acta de nacimiento Original y Copia.</li>
                </ul>
                <p><strong>Responsable:&nbsp;</strong>Germán Menchaca Rubio.</p>
                <p><strong>Teléfono:&nbsp;</strong>8718707.</p>
                </div>`
            },
            {
                name: 'Constancia de residencia',
                notes: `<div id="articlewrap">
                <p><strong>Oficina Receptora:</strong><strong>&nbsp;Oficialía Mayor.</strong></p>
                <p><strong>Costo:&nbsp;</strong><strong>$264.00</strong></p>
                <p><strong>Horario de atención:</strong><strong>&nbsp;De Lunes a Viernes de 8:00 a.m. a 15:00 p.m.<br>
                Duración de trámite:&nbsp;24 horas.<br>
                Domicilio donde se realiza:&nbsp;Av. López Mateos s/n, Col. del Bosque.</strong></p>
                <p><strong>Responsable:&nbsp;</strong><strong>Brenda Aracely Pacheco Obeso<br>
                Lugar donde se paga:&nbsp;Tesorería Municipal.<br>
                Requisitos:</strong></p>
                <ul>
                <li><strong>Copia de Comprobante de Domicilio</strong></li>
                <li><strong>Copia de Identificación Oficial. En caso de no tener Identificación Oficial, Acta de Nacimiento, Certificado de Estudio.</strong></li>
                </ul>
                <p><strong>Teléfono:&nbsp;</strong><strong>8718707</strong></p>
                <p>&nbsp;</p>
                </div>`
            },
            {
                name: 'Constancia de dependencia económica',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong><strong>&nbsp;$264.00<br>
                Horario de atención:&nbsp;De Lunes a Viernes de 8:00 a.m. a 15:00 p.m.<br>
                Duración de trámite:&nbsp;24 horas.<br>
                Domicilio donde se realiza:&nbsp;Av. López Mateos s/n, Col. del Bosque.<br>
                Lugar donde se paga:&nbsp;Tesorería Municipal.<br>
                Requisitos:</strong></p>
                <ul>
                <li><strong>Copia de Comprobante de Domicilio Reciente.</strong></li>
                <li><strong>Copia de Identificación Oficial.</strong></li>
                <li><strong>Copia de Acta de nacimiento.</strong></li>
                <li><strong>Copia de Comprobante de Ingresos.</strong></li>
                </ul>
                <p><strong>Oficina Receptora:</strong><strong>&nbsp;Oficialía Mayor.</strong></p>
                <p><strong>Responsable:&nbsp;</strong><strong>Brenda Aracely Pacheco Obeso</strong></p>
                <p><strong>Teléfono:&nbsp;</strong><strong>8718707</strong></p>
                </div>`
            },
            {
                name: 'Permiso de Vía pública',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;$400.00<br>
                <strong>Horario de atención:</strong>&nbsp;De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Inmediato.<br>
                <strong>Domicilio donde se realiza:</strong>&nbsp;Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Copia de Credencial de Elector.</li>
                <li>Copia del Permiso Anterior (Solo para Renovación).</li>
                <li>Certificado Médico en caso de manejo de Alimentos (Expedido por el Centro de Salud).</li>
                <li>4 Fotografías Infantiles.</li>
                <li>Carta de Anuencia de los vecinos que les corresponda el derecho de banqueta con copia de la credencial de Elector del propietario.</li>
                <li>Solicitud del trámite expedida por Vía Pública.</li>
                <li>Licencia Sanitaria y/o curso de manejo de alimentos expedida por Salud Municipal.</li>
                <li>Aprobación de la Dirección de Tránsito Municipal de no obstruir el tránsito Peatonal o Vehicular.</li>
                <li>Carta de Aprobación de Protección Civil.</li>
                </ul>
                <p><strong>Responsable:&nbsp;</strong>Myriam Lizbeth Gaxiola Hernández.</p>
                <p><strong>Oficina Receptora:&nbsp;</strong>Vía Pública.</p>
                </div>`
            }
        ]
    },
    {
        main: 'Salud Pública',
        list: [
            {
                name: 'Inspecciones de Quejas Sanitarias'
            },
            {
                name: 'Inspecciones Sanitarias a Puestos Fijos y Semi-Fijos'
            },
            {
                name: 'Brigada Médica'
            },
            {
                name: 'Consulta médica'
            },
            {
                name: 'Revisión a meretrices que trabajan en bares y cantinas'
            },
            {
                name: 'Fumigación'
            },
            {
                name: 'Esterilización de Perros y Gatos'
            }
        ]
    },
    // {
    //     main: 'Dirección General De Desarrollo Económico',
    //     list: [
    //         {
    //             name: 'Promoción de Inversiones',
    //             description: 'Se brinda asesoría sobre terrenos y/o locales comerciales en venta o renta disponibles para la instalación de nuevas empresas en el municipio; el requisito es presentar la Descripción General del Proyecto.'
    //         },
    //         {
    //             name: 'Ceprofies',
    //             description: 'Se apoya al inversionista con el trámite de Certificados de Promoción Fiscal del Estado de Sinaloa, a fin de apoyar e incentivar la inversión en el municipio con la exención del pago de impuestos municipales y estatales por un lapso de 3 o hasta 5 años según sea el caso.'
    //         },
    //         {
    //             name: 'Dirección de Fomento Económico'
    //         },

    //     ]
    // },
    // {
    //     main: 'Turismo',
    //     type: 'single'
    // },
    {
        main: 'Desarrollo Social (SEDESOL)',
        list: [
            {
                name: 'Apoyos a la educación (secundaria, preparatoria y profesional)',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.<br>
                <strong>Costo:</strong> Gratuito.<br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 17:00 p.m.<br>
                <strong>Duración de trámite:</strong> 6 meses.<br>
                <strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>2 Fotografías.</li>
                <li>Constancia de Estudios.</li>
                <li>Copia del Acta de Nacimiento.</li>
                <li>Copia de un recibo de pago de inscripción.</li>
                <li>Copia de la credencial de estudiante.</li>
                </ul>
                </div>`
            },
            {
                name: 'Centros de salud (rehabilitación, construcción y equipamiento)',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.<br>
                <strong>Costo: </strong>La gestión es gratuita, para la rehabilitación y construcción es necesario aportar el 20% del costo total de la obra.<br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 17:00 p.m.<br>
                <strong>Duración de trámite: </strong>1 a 2 meses.<br>
                <strong>Domicilio donde se realiza:</strong> Jurisdicción Sanitaria # 2, Dr. de la Torre esq. Guillermo Nelson.<br>
                <strong>Lugar donde se paga:</strong> &nbsp;&nbsp; &nbsp;–<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Solicitud elaborada.</li>
                <li>Cubrir el 20% del costo total de la obra.</li>
                </ul>
                </div>`
            },
            {
                name: 'Créditos para proyectos productivos',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.<br>
                <strong>Costo:</strong> Gratuito.<br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 16:00 p.m.<br>
                <strong>Duración de trámite: </strong>6 meses.<br>
                <strong>Domicilio donde se realiza: </strong>Av. Lázaro Cárdenas s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–</p>
                <p><strong>Requisitos:</strong></p>
                <ul>
                <li>Copia de la Credencial de Elector</li>
                <li>Cotización</li>
                <li>Elaboración del Proyecto</li>
                </ul>
                </div>`
            },
            {
                name: 'Electrificación',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo:</strong> La gestión del trámite es gratuita. Aportación del 20% del costo total de la obra.</p>
                <p><strong>Horario de atención:</strong> De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de trámite:</strong> 2 meses.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Lázaro Cárdenas s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga: </strong>–</p>
                <p><strong>Requisitos:</strong></p>
                <ul>
                <li>Presupuesto elaborado por C.F.E.</li>
                <li>Cubrir el 20% del costo total de la obra.</li>
                </ul>
                </div>`
            },
            {
                name: 'Estímulos a la educación básica',
                reqs: `<ul>
                <li>Copia del acta de nacimiento.</li>
                <li>1 fotografía tamaño infantil, blanco y negro.</li>
                <li>Asistir a la reunión el día y hora que se asigne a la escuela correspondiente.</li>
                </ul>`,
                notes: `<p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.<br>
                <strong>Costo:</strong> Gratuito<br>
                <strong>Horario de Atención:</strong> De 8:00 a.m. a 17:00 p.m.<br>
                <strong>Duración de Trámite:</strong> Cada inicio de ciclo escolar<br>
                <strong>Domicilio donde se realiza:</strong> Av. Lázaro Cárdenas s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–</p>`
            },
            {
                name: 'Guarniciones, banquetas y alumbrado público',
                notes: `<p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.<br>
                <strong>Costo: </strong>La gestión del trámite es gratuita.<br>
                <strong>Costo por lámpara:</strong> $ 1,300.00, se requiere aportar el 20 % del costo.<br>
                <strong>Horario de atención: </strong>De 8:00 a.m. a 17:00 p.m.<br>
                <strong>Duración de trámite: </strong>1 a 2 meses<br>
                <strong>Domicilio donde se realiza:</strong> Av. Lázaro Cárdenas s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong> &nbsp;&nbsp; &nbsp;–</p>`,
                reqs: `<ul>
                <li>Solicitud Especificando:
                <ul>
                <li>Cantidad de lámparas</li>
                <li>Comunidad</li>
                <li>Sindicatura</li>
                </ul>
                </li>
                </ul><ul>
                <li>Cubrir el 20% del valor total de la lámpara</li>
                </ul>`
            },
            {
                name: 'Infraestructura educativa (construcción)',
                notes: `<p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.<br>
                <strong>Costo: </strong>La gestión de la obra es gratuita. Se aporta el 20% del costo total de la obra.<br>
                <strong>Horario de atención: </strong>De 8:00 a.m. a 17:00 p.m.<br>
                <strong>Duración de trámite: </strong>1 a 2 meses.<br>
                <strong>Domicilio donde se realiza:</strong> Av. Lázaro Cárdenas s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–</p>`,
                reqs: `<ul>
                <li>Solicitud:
                <ul>
                <li>Nombre de la escuela</li>
                <li>Comunidad</li>
                <li>Sindicatura</li>
                </ul>
                </li>
                <li>Cubrir el 20% del costo total de la obra.</li>
                </ul>`
            },
            {
                name: 'Vivienda digna (construcción 4 x 4)',
                notes: `<p>
                <strong>Costo:</strong> La gestión del trámite es gratuita, el apoyo es de $13,591.74 y es crédito a la palabra.<br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 17:00 p.m.<br>
                <strong>Duración de trámite:</strong> 2 meses.<br>
                <strong>Domicilio donde se realiza: </strong>Av. Lázaro Cárdenas s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–</p>`,
                reqs: `<ul>
                <li>Copia del acta de nacimiento.</li>
                <li>Copia de la credencial de elector.</li>
                <li>Copia del acta de matrimonio (opcional).</li>
                <li>Constancia de residencia (expedida por la autoridad de la comunidad o jefe de manzana).</li>
                <li>Comprobante de domicilio.</li>
                <li>Comprobante de la posesión del terreno.</li>
                </ul>`
            },
            {
                name: 'Vivienda digna (rehabilitación, techo, piso y enjarre)Final del formulario',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.</p>
                <p><strong>Costo:</strong> La gestión del trámite es gratuita.</p>
                <p><strong>Techo:</strong> $2,693.00</p>
                <p><strong>Piso: </strong>$1,634.00</p>
                <p><strong>Enjarre:</strong> $2,056.00</p>
                <p><strong>Horario de atención:</strong> De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de trámite: </strong>2 meses.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Lázaro Cárdenas s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga: </strong>–</p>
                </div>`,
                reqs: `<ul>
                <li>Copia del acta de nacimiento.</li>
                <li>Copia de la credencial de elector.</li>
                <li>Copia del acta de matrimonio.</li>
                <li>Constancia de residencia.</li>
                <li>Comprobante de domicilio.</li>
                <li>Comprobante de posesión de terreno.</li>
                </ul>`
            }
        ]
    },
    {
        main: 'Participación Ciudadana',
        list: [
            {
                name: 'Asesoría al comité de participación ciudadana',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.</p>
                <p><strong>Costo:</strong> Gratuito.</p>
                <p><strong>Horario de Atención: </strong>De 8:00 a.m. a 15:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>1 a 3 hrs.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n Col. del Bosque.</p>
                <p><strong>Lugar donde se paga:</strong> –</p>
                </div>`,
                reqs: `<ul>
                <li>Solicitud de los integrantes del comité.</li>
                <li>Presentarse en la oficina.</li>
                </ul>`
            },
            {
                name: 'Construcción de comités de participación ciudadana',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo:</strong> Gratuito.</p>
                <p><strong>Horario de Atención: </strong>De 8:00 a.m. a 15:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>3 días.</p>
                <p><strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n Col. del Bosque.</p>
                <p><strong>Lugar donde se paga: </strong>–</p>
                </div>`,
                reqs: `<ul>
                <li>Solicitud de constitución del comité por parte de las autoridades o interesados de cada localidad.</li>
                </ul>`
            },
            {
                name: 'Cortes de caja de eventos de especulación',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención:</strong> De 8:00 a.m. a 15:00 p.m.</p>
                <p><strong>Duración de Trámite:</strong> 3 días.</p>
                <p><strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n Col. del Bosque.</p>
                <p><strong>Lugar donde se paga:</strong> –</p>
                <p><strong>Requisitos:</strong> Comprobantes de gastos.</p>
                </div>`
            }
        ]
    },
    {
        main: 'Ecología',
        list: [
            {
                name: 'Atención de quejas y/o denuncias',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención: </strong>De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>1 día.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga:</strong> &nbsp;&nbsp; &nbsp;–</p>
                <p><strong>Requisitos:</strong> Presentación de la queja y/o denuncia.</p>
                </div>`
            },
            {
                name: 'Entrega de plantas',
                notes: `<div id="articlewrap">
                <p><strong>Descripción:</strong> Su objetivo es promover la reforestación en terrenos ociosos, creación de corredores ecológicos y propiciar las condiciones para que se presenten las precipitaciones pluviales en su época y forma normal.</p>
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención:</strong> De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>De 1 a 5 días dependiendo de la cantidad solicitada.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga:</strong> &nbsp;&nbsp; &nbsp;–</p>
                <p><strong>Requisitos: </strong>Solicitud por escrito</p>
                </div>`
            },
            {
                name: 'Manifestación de impacto y riesgo ambiental',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre 2003.</p>
                <p><strong>Costos:</strong></p>
                <ul>
                <li>Modalidad general: 80 salarios mínimos.</li>
                <li>Modalidad intermedia: 120 salarios mínimos.</li>
                <li>Modalidad específica: 160 salarios mínimos.</li>
                <li>Análisis de riesgo ambiental: 80 salarios mínimos.</li>
                <li>Horario de atención: 8 a 15 hrs.</li>
                </ul>
                <p><strong>Duración de trámite:</strong></p>
                <ul>
                <li>Modalidad general: 15 días.</li>
                <li>Modalidad intermedia: 25 días.</li>
                <li>Modalidad específica: 25 días.</li>
                <li>Análisis de riesgo ambiental: 20 días</li>
                </ul>
                <p><strong>Domicilio donde se realiza: </strong>Blvd. Alfonso G. Calderón #115-42 Edificio Llanes.</p>
                <p><strong>Lugar donde se paga:</strong> Tesorería municipal.</p>
                </div>`,
                reqs: `<ul>
                <li>Licencia de uso de suelo, alineamiento y número oficial y/o copia.</li>
                <li>Modalidad correspondiente contestada.</li>
                </ul>`
            },
            {
                name: 'Permiso de derribo de árboles',
                notes: `<div id="articlewrap">
                <p><strong>Descripción:</strong> Tiene como objetivo controlar la deforestación innecesaria de los elementos naturales, así como preservar las especias en vías de extinción.</p>
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención: </strong>De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite:</strong> 1 día.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–</p>
                </div>`,
                reqs: `<ul>
                <li>Solicitud por escrito.</li>
                <li>Hacer solicitud.</li>
                <li>Que exista justificación.</li>
                <li>Mediante Inspección.</li>
                <li>Compromiso de sembrar 2 árboles por cada derribado.</li>
                </ul>`
            },
            {
                name: 'Permiso de quema de soca',
                notes: `<div id="articlewrap">
                <p><strong>Descripción: </strong>El objetivo de este permiso es evitar el calentamiento global de la tierra y los efectos de la sequía.</p>
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención:</strong> De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>1 día</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga: </strong>&nbsp;&nbsp; &nbsp;–</p>
                </div>`,
                reqs: `<ul>
                <li>Hacer solicitud por escrito.</li>
                <li>Que exista justificación.</li>
                <li>Mediante inspección.</li>
                <li>Copia del certificado parcelario.</li>
                <li>Copia de la credencial de Elector.</li>
                <li>Constancia por parte de persona u organismo autorizado (Obtener a través de un Ing. Agrónomo).</li>
                </ul>`
            },
            {
                name: 'Plantas para la reforestación masiva',
                notes: `<div id="articlewrap">
                <p><strong>Descripción:</strong> Promover la reforestación en terrenos ejidales ociosos, obteniendo provechos económicos a corto plazo con su explotación de planta maderable.</p>
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención:</strong> De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite:</strong> 8 días.</p>
                <p><strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga:</strong></p>
                </div>`,
                reqs: `<ul>
                <li>Ser propietario poseedor del predio.</li>
                <li>Solicitud por escrito especificando variedad y cantidad de árboles.</li>
                <li>Copia del titulo del predio.</li>
                <li>Carta compromiso de responsabilidad sobre la planta entregada.</li>
                <li>Estudio del predio en el que especifica tipo de suelo, con el fin de determinar la especie que se adapte al terreno.</li>
                </ul>`
            },
            {
                name: 'Registro de empresas generadoras de residuos peligrosos (Aceites gastados)',
                notes: `<div id="articlewrap">
                <p><strong>Descripción:</strong> El objetivo es tener mayor control con el tratamiento, manejo y disposición final de residuos peligrosos por empresas de tal giro, para evitar la contaminación de agua y suelo.</p>
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo:</strong> $27.00.</p>
                <p><strong>Horario de Atención: </strong>De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>15 días.</p>
                <p><strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Lugar donde se paga:</strong></p>
                <p><em>Nota: Todo original y 4 copias</em></p>
                </div>`,
                reqs: `<ul>
                <li>Formulario SAT 5 (triplicado).</li>
                <li>Pago de derechos en el banco de su preferencia.</li>
                <li>Formulario de registro.</li>
                <li>Oficio de solicitud dirigido a: José Domínguez Rodríguez, delegado Estatal de Semarnat.</li>
                </ul>`
            },
            {
                name: 'Trámite de denuncia popular ante Autoridad Ecológica Estatal y Federal',
                notes: `<div id="articlewrap">
                <p><strong>Descripción:</strong> El objetivo es tener mayor control con el tratamiento, manejo y disposición final de residuos peligrosos por empresas de tal giro, para evitar la contaminación de agua y suelo.</p>
                <p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención: </strong>De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite:</strong> 5 días.</p>
                <p><strong>Domicilio donde se realiza: </strong>Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Requisitos:</strong> Presentación de la denuncia vía personal o telefónica de hechos mediante la verificación.</p>
                </div>`
            },
            {
                name: 'Visto bueno para el permiso de colocación de anuncios publicitarios (Mantas, espectaculares)',
                notes: `<div id="articlewrap">
                <p><strong>Descripción:</strong> Su objetivo es evitar la contaminación visual en el municipio, permitiéndolo solo en casos donde no se dañe el entorno, es decir, que haya armonía entre lo que se anuncia, su forma o lugar de colocación y las necesidades de la población.</p>
                <p><strong>Vigencia a partir de: </strong>17 de Septiembre de 2003.</p>
                <p><strong>Costo: </strong>Gratuito.</p>
                <p><strong>Horario de Atención:</strong> De 8:00 a.m. a 17:00 p.m.</p>
                <p><strong>Duración de Trámite: </strong>1 día.</p>
                <p><strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.</p>
                <p><strong>Requisitos:</strong> Presentación de la denuncia vía personal o telefónica de hechos mediante la verificación.</p>
                </div>`
            }
        ]
    },
    // {
    //     main: 'Unidad Rápida de Gestión Empresarial (URGE)',
    //     type: 'single',
    //     description: 'Es el vínculo en los municipios de la Secretaría de Desarrollo Económico del Gobierno del Estado  y es la encargada de brindar información y asesoría a los empresarios, además de realizar la gestión de los trámites de constitución y apertura de sus empresas, resolución de sus trámites en problemas con las diferentes instancias de gobierno y de igual manera, lo vincula a los programas de apoyo de las tres órdenes de gobierno.'
    // },
    {
        main: 'Educación y Cultura',
        list: [
            {
                name: 'Atención a Discapacitados',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>Gratuito.</p>
                <p><strong>Horario de atención:</strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>Variable.<br>
                <strong>Domicilio donde se realiza:</strong>Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Servicios:</strong></p>
                <ol>
                <li><strong>Credencialización: </strong><u>Requisitos:</u> Fotografía tamaño Credencial o infantil y Certificado o dictamen que acredite el tipo de discapacidad.</li>
                <li><strong>Canalización a tratamiento de equino terapia.</strong></li>
                <li><strong>Gestión para trámites de todo tipo de apoyo ante dependencias gubernamentales y no gubernamentales.</strong></li>
                <li><strong>Asesoría para la elaboración de proyectos productivos.</strong></li>
                <li><strong>Se cuenta con el servicio de visitas domiciliarias para la atención de todas quellas personas discapacitadas que no puedan moverse de su domicilio.</strong><u> Requisitos:</u>Comunicarse para agendar cita a los teléfonos: 8718705.</li>
                </ol>
                <p><strong>Responsable:</strong>Lic. Xóchitl Berenica Soto Fierro.</p>
                </div>`
            },
            {
                name: 'Trámites para Uso de la Fosa Común',
                notes: `<div id="articlewrap">
                <p><strong>Costo:</strong>&nbsp;Gratuito.</p>
                <p><strong>Horario de atención:</strong>&nbsp;De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Requisitos:</strong></p>
                <ul>
                <li>Oficio de recibido del ministerio público y funerarias.</li>
                </ul>
                </div>`
            },
            {
                name: 'Solicitud de Apoyo a Escuelas',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Servicio:&nbsp;</strong>Recibir y darle seguimiento a toda solicitud hecha por los múltiples planteles educativos del municipio. Las solicitudes abarcan desde mobiliario, material didáctico, aires acondicionados, bandas de guerra, banderas, aparatos de sonido y pintura.</p>
                <p><strong>Costo:</strong>&nbsp;Gratuito.<br>
                <strong>Horario de atención:</strong>&nbsp;De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong>&nbsp;Variable.<br>
                <strong>Domicilio donde se realiza:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.
                </div>`,
                reqs: `<ul>
                <li>Oficio dirigido al Presidente Municipal con atención a la Directora General de Educación, Cultura y Promoción Social.</li>
                </ul>`
            },
            {
                name: 'Orientación y Gestión de Becas Estatales y Federales',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Servicio:</strong>&nbsp;Brindar Orientación y Apoyo necesarios para facilitar el acceso a las diferentes becas creadas por los gobiernos estatal y federal, para promover y propiciar que estudiantes en situación económica difícil , con promedio sobresaliente puedan continuar con su formación y desarrollo académico en cualquier nivel educativo.</p>
                <p><strong>Costo:</strong>&nbsp;Gratuito.</p>
                <p><strong>Horario de atención:</strong>&nbsp;De 8:00 a.m. a 15:00 p.m.</p>
                <p><strong>Duración de trámite:</strong>&nbsp;Inmediato.<br>
                <strong>Domicilio donde se realiza:</strong>&nbsp;Av. Adolfo López Mateos s/n Col. del Bosque.<br>
                <strong>Lugar para acudir a realizarlo:</strong>&nbsp;Dirección General de Educación, Cultura y Promoción Social.<br>
                <strong>Comunicarse a los teléfonos:&nbsp;</strong>871 8705, Conmutador: 871 8700&nbsp; Ext. 137.
                </div>`,
                reqs: `<ul>
                <li>BECASIN (PRIMARIA, SECUNDARIA, PREPARATORIA Y UNIVERSIDAD)</li>
                <li>Boleta o Kardex con calificaciones</li>
                <li>Constancia de Inscripción del Ciclo Escolar para el que se solicita la beca.</li>
                <li>Carta de Buena Conducta</li>
                <li>Acta de Nacimiento</li>
                <li>Curp</li>
                <li>Comprobante de Domicilio</li>
                <li>Comprobante de Ingresos (Tutor)</li>
                <li>Carta Motivos PRONABE (Licenciatura)</li>
                <li>Original de Constancia de inscripción o Kardex</li>
                <li>Copia de Certificado de bachillerato (Solo alumno de primer grado)</li>
                <li>Original de Constancia de Ingresos económicos de la familia</li>
                <li>Copia de Acta de Nacimiento</li>
                <li>Una fotografía tamaño infantil</li>
                <li>Copia de Comprobante de Domicilio (reciente)</li>
                </ul>`
            },
            {
                name: 'Credencial y Uso de Bibliotecas Públicas del Municipio',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Servicio:&nbsp;</strong>Buscar la formación de lectores mediante las consultas de textos y prestamos a domicilio y así fomentar el hábito a través de lectura, escritura e integración, las cuales pretenden la vinculación entre el usuario y el acervo, con el objetivo de formar lectores activos , reflexivos y críticos, así como también áreas de tareas, área de computo donde realizar actividades escolares por medio del internet totalmente gratuito.</p>
                <p><strong>Costo:</strong>&nbsp;Gratuito</p>
                <p><strong>Horario de atención:</strong>&nbsp;De 8:00 a.m. a 15:00 p.m.</p>
                <p><strong>Duración de trámite:</strong>&nbsp;Inmediato.<br>
                <strong>Domicilio donde se realiza:</strong>&nbsp;Av. Adolfo López Mateos s/n Col. del Bosque.<br>
                <strong>Lugar para acudir a realizarlo:</strong>&nbsp;Dirección General de Educación, Cultura y Promoción Social.<br>
                <strong>Comunicarse a los teléfonos:</strong>&nbsp;871 8705, Conmutador: 871 8700&nbsp; Ext. 137.
                </div>`,
                reqs: `<ul>
                <li>Llenar solicitud 1 fiador con teléfono local</li>
                <li>No ser estudiante</li>
                <li>Comprobante de Domicilio</li>
                <li>2 Fotografías tamaño infantil</li>
                </ul>`
            },
            {
                name: 'Constancia de Insolvencia económica',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de trámite:&nbsp;</strong>Elaborar de manera inmediata constancias de insolvencia económica, para personas que no cuentan con comprobante de ingresos para trámite de becas y vivienda.</p>
                <p><strong>Costo:</strong> Gratuito.<br>
                <strong>Horario de atención: </strong>De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong> Inmediato.<br>
                <strong>Domicilio donde se realiza:</strong> Av. Adolfo López Mateos s/n, Col. del Bosque.
                </div>`,
                reqs: `<ul>
                <li>Credencial de Elector.</li>
                <li>Comprobante de Domicilio.</li>
                <li>Datos generales de dependientes económicos.</li>
                <li>Percepciones Económicas mensuales.</li>
                </ul>`
            },
            {
                name: 'Descuentos en Lineas de Transporte',
                notes: `<div id="articlewrap">
                <p><strong>Tipo de Servicio:&nbsp;</strong>Proporcionar oficio donde consta que el solicitante es candidato a descuento en lineas de transporte, con las que la Dirección tiene un convenio preestablecido.</p>
                <p><strong>Costo:</strong> Gratuito.<br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite:</strong> Variable.<br>
                <strong>Domicilio donde se realiza:</strong> Ave. López Mateos s/n, Col. del Bosque.
                </div>`,
                reqs: `<ul>
                <li>Credencial de elector.</li>
                </ul>`
            }
        ]
    },
    {
        main: 'Seguridad Pública',
        list: [
            {
                name: 'Constancias de No faltas al Bando de policía y buen Gobierno',
                notes: `<p><strong>Costo: $264.00</strong><br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 15:00 p.m.<br>
                <strong>Duración de trámite: </strong>15 Minutos.<br>
                <strong>Domicilio donde se realiza: </strong>Carretera a León Fonseca Km. 1.5.<br>
                <strong>Lugar donde se paga: </strong>Tesorería Municipal.<br>
                <strong>Requisitos:</strong></p>`,
                reqs: `<ul>
                <li><span>Credencial de Elector Original y Comprobante de pago del tramite.</span></li>
                </ul>`
            }
        ]
    },
    // {
    //     main: 'Tránsito municipal',
    //     list: [
    //         {
    //             name: 'Servicios'
    //         }
    //     ]
    // },
    {
        main: 'Obras y Servicios públicos',
        list: [
            {
                name: 'Licencia de Construcción',
                notes: `<div class="entry-content" itemprop="text">
                <h3 style="text-align: center;"><span style="font-weight: 400; font-size: 24pt;">Licencia de Construcción</span></h3>
    <p><b>Vigencia a partir de:&nbsp;</b><span style="font-weight: 400;">01 de marzo de 2020.</span><span style="font-weight: 400;"><br>
    </span><b>Costo:&nbsp;</b><span style="font-weight: 400;">El precio del metro cuadrado varía de acuerdo a la inversión de la construcción, este oscila de la siguiente maneara:</span></p>
    <p>&nbsp;</p>
    <table style="border-color: #EC0928;">
    <tbody>
    <tr>
    <td colspan="5"><b>OBRA NUEVA</b></td>
    </tr>
    <tr>
    <td><b>MODALIDAD</b></td>
    <td><b>DE</b></td>
    <td><b>HASTA</b></td>
    <td><b>BASE GRAVABLE POR M^2</b></td>
    <td><b>% DE COBRO</b></td>
    </tr>
    <tr>
    <td>A)Expedición de Licencias de construcción con valor de hasta 5,500 U.M.A.</td>
    <td><span style="font-weight: 400;">$0.01</span></td>
    <td><span style="font-weight: 400;">$477,840.00</span></td>
    <td><span style="font-weight: 400;">$3,580.00</span></td>
    <td><span style="font-weight: 400;">0.125</span></td>
    </tr>
    <tr>
    <td>B)Expedición de Licencias de construcción con valor de 5,501 hasta 9,500 U.M.A.</td>
    <td><span style="font-weight: 400;">$477,840.01</span></td>
    <td><span style="font-weight: 400;">$825,360.00</span></td>
    <td><span style="font-weight: 400;">$5,138.00</span></td>
    <td><span style="font-weight: 400;">0.625</span></td>
    </tr>
    <tr>
    <td>C)Expedición de Licencias de construcción con valor de 9,501 en adelante</td>
    <td><span style="font-weight: 400;">$825,360.01</span></td>
    <td><span style="font-weight: 400;">En adelante</span></td>
    <td><span style="font-weight: 400;">$6,459.00</span></td>
    <td><span style="font-weight: 400;">1.25</span></td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="border-color: #EC0928;">
    <tbody>
    <tr>
    <td colspan="5"><b>AMPLIACION Y REMODELACION</b></td>
    </tr>
    <tr>
    <td><b>MODALIDAD</b></td>
    <td><b>DE</b></td>
    <td><b>HASTA</b></td>
    <td><b>BASE GRAVABLE POR M^2</b></td>
    <td><b>% DE COBRO</b></td>
    </tr>
    <tr>
    <td><span style="font-weight: 400;">A) Expedición de Licencias de construcción con valor de hasta <b>500 U.M.A.</b></span></td>
    <td><span style="font-weight: 400;">$0.01</span></td>
    <td><span style="font-weight: 400;">$43,440.00</span></td>
    <td><span style="font-weight: 400;">$3,580.00</span></td>
    <td><span style="font-weight: 400;">0.125</span></td>
    </tr>
    <tr>
    <td><span style="font-weight: 400;">B) Expedición de Licencias de construcción con valor de <b>5,501 </b>hasta <b>9,500 U.M.A.</b></span></td>
    <td><span style="font-weight: 400;">$43,440.01</span></td>
    <td><span style="font-weight: 400;">En adelante</span></td>
    <td><span style="font-weight: 400;">$3,079.00</span></td>
    <td><span style="font-weight: 400;">1.25</span></td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <table style="border-color: #EC0928;">
    <tbody>
    <tr>
    <td><b>COSTO MINIMO DE 16 HASTA 24 M2</b></td>
    <td><span style="font-weight: 400;">Base Gravable</span></td>
    <td><span style="font-weight: 400;">$43,441.00</span></td>
    </tr>
    <tr>
    <td><b>PAGO MINIMO-&gt;</b></td>
    <td colspan="2"><b>$543.00</b></td>
    </tr>
    </tbody>
    </table>
    <p><b>NOTA: </b><span style="font-weight: 400;">Para la selección de la base gravable por m</span><span style="font-weight: 400;">2</span><span style="font-weight: 400;"> será revisado el presupuesto presentado ante esta dependencia para ver si cumple con los estándares de valor según el mercado actual.</span></p>
    <div id="articlewrap">
    <p><strong>Horario de atención: </strong>8 a 15 hrs.<br>
    <strong>Duración de trámite: </strong>1 a 3 días&nbsp; hábiles.<br>
    Lugar donde acudir: Dirección de Planeación Urbana.<br>
    <strong>Dirección:</strong> Ave. Adolfo López Mateos s/n, Col. del Bosque.<br>
    <strong>Lugar donde se paga: </strong>Tesorería Municipal.</p>
    <p><strong>Requisitos:</strong></p>
    <table style="border-color: #EC0928;" border="0" cellspacing="0" cellpadding="0" align="left">
    <colgroup>
    <col width="250">
    <col width="250">
    <col width="250">
    <col width="250"></colgroup>
    <tbody>
    <tr>
    <td class="xl63" colspan="2"><strong>Obras menores de 60 m2</strong><strong>.</strong></td>
    <td class="xl64" colspan="2"><strong>Obras mayores de 60 m2</strong><strong>.</strong></td>
    </tr>
    <tr>
    <td class="xl72" colspan="2" rowspan="1" width="269" height="15">– Copia de Escrituras o constancia de posesión</td>
    <td class="xl66" colspan="2" rowspan="1">– Copias de Escrituras o constancia de posesión.</td>
    </tr>
    <tr>
    <td class="xl73" colspan="2"><strong>Plano de Construcción con los siguientes requisitos:</strong></td>
    <td class="xl74" colspan="2"><strong>&nbsp;Plano de construcción con los siguientes requisitos:</strong></td>
    </tr>
    <tr>
    <td class="xl72" colspan="2" rowspan="1" width="269" height="15">. Solicitud por Escrito</td>
    <td class="xl66" colspan="2" rowspan="1">. Solicitud por Escrito &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; . Croquis de Localización</td>
    </tr>
    <tr>
    <td class="xl72" colspan="2" rowspan="1" width="269" height="15">· Planta Cimentación</td>
    <td class="xl66" colspan="2" rowspan="1">· Planta de Cimentación &nbsp; &nbsp;· Carta de Posesión Notariada</td>
    </tr>
    <tr>
    <td class="xl72" colspan="2" rowspan="1" width="269" height="15">· Fachada Principal</td>
    <td class="xl66" colspan="2" rowspan="1">· Planta de Armado de Losa &nbsp; · Planta Arquitectónica</td>
    </tr>
    <tr>
    <td class="xl71" colspan="2" rowspan="1" width="269" height="15">· Ubicación</td>
    <td class="xl65" colspan="2" rowspan="1">· Planta de Azotea</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15">. Croquis Localización</td>
    <td class="xl66" colspan="2" rowspan="1">· Fachada Principal</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15">· Planta Arquitectónica</td>
    <td class="xl66" colspan="2" rowspan="1">· Corte de Sanitarios</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15">· Carta de Posesión Notariada</td>
    <td class="xl66" colspan="2" rowspan="1">· Detalles Estructurales</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15"></td>
    <td class="xl66" colspan="2" rowspan="1">· Detalles de Cimentación</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15"></td>
    <td class="xl66" colspan="2" rowspan="1">· Detalles Eléctricos</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15"></td>
    <td class="xl66" colspan="2" rowspan="1">· Simbología</td>
    </tr>
    <tr>
    <td class="xl67" colspan="2" rowspan="1" width="269" height="15"></td>
    <td class="xl66" colspan="2" rowspan="1">· Ubicación</td>
    </tr>
    </tbody>
    </table>
    <p><strong>Nota:</strong> Es necesario estar firmado por <span style="font-weight: 400;">Director Responsable de Obra (D.R.O.)</span></p>
    <ul>
    <li><span style="font-weight: 400;">Llenar forma firmada por Director Responsable de Obra (D.R.O.) (Ingeniero Civil o Arquitecto) registrado en la Dirección de Planeación Urbana.</span></li>
    <li>Solicitar número oficial.</li>
    <li>Solicitar alineamiento.</li>
    </ul>
    </div>					</div>`
            },
            {
                name: 'Deslinde de terreno',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de: </strong>01 de Marzo de 2020.<br>
                <strong>Costo: </strong>$0.65 por metro cuadrado (hasta 1,000 m2).<br>
                <strong>Horario de atención: </strong>8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>3 días hábiles.<br>
                <strong>Lugar donde acudir a realizarlo:</strong> Dirección de Planeación Urbana.<br>
                <strong>Dirección:</strong> Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong> Tesorería Municipal.</p>
                </div>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Copia de las Escrituras de la Propiedad</li>
                <li>Carta de Posesión Notariada</li>
                <li>Croquis de Localización</li>
                </ul>`
            },
            {
                name: 'Alineamiento',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 01 de Marzo de 2020.<br>
                <strong>Costo: </strong>$162.90 por los primeros 10 metros lineales y por cada metro excedente $16.29<br>
                <strong>Horario de atención:</strong> 8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>3 días hábiles.<br>
                <strong>Lugar donde acudir a realizarlo:</strong> Dirección de Planeación Urbana.<br>
                <strong>Dirección: </strong>Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong> Tesorería Municipal.</p>
                </div>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Copia de las Escrituras de la Propiedad</li>
                <li>Croquis de Localización</li>
                <li>Carta de Posesión Notariada.</li>
                </ul>`
            },
            {
                name: 'Licencia Director responsable de obra (D.R.O.)',
                notes: `<div id="articlewrap">
                <p><strong>Vigencia a partir de:</strong> 01 de Marzo de 2020.<br>
                <strong>Costo:</strong> $1050.00 anual.<br>
                <strong>Horario de atención:</strong> 8 a 15 hrs.<br>
                <strong>Duración de trámite:</strong> 24 hrs.<br>
                <strong>Domicilio donde se realiza: </strong>Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde acudir:</strong> Dirección de Planeación Urbana.<br>
                <strong>Lugar donde se paga:</strong> Tesorería municipal.</p>
                </div>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Autorización del Colegio al que pertenezca.</li>
                <li>Copia de la Cédula profesional.</li>
                <li>Copia de la Constancia del Curso</li>
                </ul>`
            },
            {
                name: 'Número oficial',
                notes: `<p><strong>Vigencia a partir de:</strong> 01 de Marzo de 2020.<br>
                <strong>Costo:</strong> $27.15 por dígito.<br>
                <strong>Horario de atención: </strong>8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>1 día hábil.<br>
                <strong>Lugar donde acudir a realizarlo:</strong> Dirección de Planeación Urbana.<br>
                <strong>Dirección: </strong>Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong> Tesorería municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Copias de las escrituras de la propiedad.</li>
                <li>Carta de Posesión Notariada.</li>
                </ul>`
            },
            {
                name: 'Permiso de construcción de bardas',
                notes: `<p><strong>Vigencia a partir de:</strong> 17 de Septiembre de 2003.<br>
                <strong>Costo: </strong>$162.90 por los primeros 10 metros lineales y por cada metro excedente $16.29<br>
                <strong>Horario de atención: </strong>8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>1 día hábil.<br>
                <strong>Domicilio donde se realiza:</strong> Av. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong> Tesorería municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Copia de las escrituras de la propiedad.</li>
                <li>Croquis de localización.</li>
                <li>Carta de Posesión Notariada.</li>
                </ul>`
            },
            {
                name: 'Permiso de construcción de monumentos, lápidas, capillas, etc.',
                notes: `<p><strong>Vigencia a partir de: 01 de Marzo de 2020.</strong><br>
                <strong>Costo:</strong> $300.00<br>
                <strong>Horario de atención: </strong>8 a 15 hrs.<br>
                <strong>Duración de trámite:</strong> Inmediato<br>
                <strong>Domicilio donde se realiza:</strong> Blvd. Benito Juárez esq. Lázaro cardenas.<br>
                <strong>Lugar donde acudir a realizarlo: </strong>Dirección de Servicios Públicos.<br>
                <strong>Lugar donde se paga: </strong>Tesorería Municipal.<br>
                <strong>Requisitos: </strong>Copia del Título de la propiedad en el panteón.</p>`
            },
            {
                name: 'Título de propiedad de lote en el panteón',
                notes: `<p><strong>Vigencia a partir de:</strong> 01 de Marzo de 2020.<br>
                <strong>Costo:</strong> $422.33 m2.<br>
                <strong>Horario de atención:</strong> 8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>Hasta 60 días.<br>
                <strong>Domicilio donde se realiza:</strong> Blvd. Benito Juarez esq. Lazaro Cárdenas.<br>
                <strong>Lugar donde se realiza:</strong> Dirección de servicios públicos.<br>
                <strong>Lugar donde se paga:</strong> Tesorería municipal.<br>
                <strong>Requisitos:</strong></p>`,
                reqs: `<ul>
                <li>Ver con el encargado del panteón las colindancias del lote.</li>
                </ul>`
            },
            {
                name: 'Permisos para la Colocación de Anuncios Luminosos y Espectáculares',
                notes: `<p><strong>Vigencia a partir de:</strong> 01 de Marzo de 2020.<br>
                <strong>Costo:</strong> Dentro de la ciudad; $24.98 m2 y Resto del municipio; $15.20 m2<br>
                <strong>Horario de atención:</strong> 8 a 15 hrs.<br>
                <strong>Duración de trámite:</strong> 8 horas a partir del siguiente día hábil de entregar la documentación completa.<br>
                <strong>Domicilio donde se realiza:</strong> Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>Tesorería municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito (Específicando Domicilio y medidas)</li>
                <li>Planos o croquis del anuncio.</li>
                <li>Croquis de ubicación del establecimiento.</li>
                <li>Copia de la póliza del seguro de daños a terceros (responsabilidad civil) solo en caso de anuncios espectaculares.</li>
                <li>Memoria de cálculo y carta responsiva de perito registrado en estructuras del Ayuntamiento.</li>
                </ul>`
            },
            {
                name: 'Demolición por Planta',
                notes: `<p><strong>Vigencia a partir de:</strong>01 de Marzo de 2020.<br>
                <strong>Costo:&nbsp;</strong>$5.43 por metro cuadrado.<br>
                <strong>Horario de atención:&nbsp;</strong>8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>1&nbsp;día hábil.<br>
                <strong>Lugar donde acudir a realizarlo:</strong>&nbsp;Dirección de Planeación Urbana.<br>
                <strong>Dirección:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Metros Cuadrados a Demoler.</li>
                <li>Croquis de Localización</li>
                </ul>`
            },
            {
                name: 'Demolición de Guarnición',
                notes: `<p><strong>Vigencia a partir de:</strong>01 de Marzo de 2020.<br>
                <strong>Costo:&nbsp;</strong>$108.60 por metro lineal.<br>
                <strong>Horario de atención:&nbsp;</strong>8 a 15 hrs.<br>
                <strong>Duración de trámite: </strong>1&nbsp;día hábil.<br>
                <strong>Lugar donde acudir a realizarlo:</strong>&nbsp;Dirección de Planeación Urbana.<br>
                <strong>Dirección:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Metros Cuadrados a Demoler.</li>
                <li>Croquis de Localización</li>
                </ul>`
            },
            {
                name: 'Obstáculo en la Vía Pública (pavimentos)',
                notes: `<p><strong>Vigencia a partir de: 01 de Marzo de 2020.</strong><br>
                <strong>Costo:&nbsp;</strong>$22.81 por metro Cuadrado por Día.<br>
                <strong>Horario de atención:&nbsp;</strong>8 a 15 hrs.<br>
                <strong>Duración de trámite:&nbsp;</strong>1&nbsp;día hábil.<br>
                <strong>Lugar donde acudir a realizarlo:</strong>&nbsp;Dirección de Planeación Urbana.<br>
                <strong>Dirección:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                </ul>`
            },
            {
                name: 'Obstáculo en la Vía Pública (Empedrado)',
                notes: `<p><strong>Vigencia a partir de:</strong>01 de Marzo de 2020.<br>
                <strong>Costo:&nbsp;</strong>$15.20 por metro Cuadrado por Día.<br>
                <strong>Horario de atención:&nbsp;</strong>8 a 15 hrs.<br>
                <strong>Duración de trámite:&nbsp;</strong>1&nbsp;día hábil.<br>
                <strong>Lugar donde acudir a realizarlo:</strong>&nbsp;Dirección de Planeación Urbana.<br>
                <strong>Dirección:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito.</li>
                </ul>`
            },
            {
                name: 'Constancias de licencias de Uso de Suelo y Diversas',
                notes: `<p><strong>Vigencia a partir de:</strong>01 de Marzo de 2020.<br>
                <strong>Costo:&nbsp;</strong>$271.50<br>
                <strong>Horario de atención:&nbsp;</strong>8 a 15 hrs.<br>
                <strong>Duración de trámite:&nbsp;</strong>1&nbsp;día hábil.<br>
                <strong>Lugar donde acudir a realizarlo:</strong>&nbsp;Dirección de Planeación Urbana.<br>
                <strong>Dirección:</strong>&nbsp;Ave. López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong>&nbsp;Tesorería Municipal.</p>`,
                reqs: `<ul>
                <li>Solicitud por Escrito</li>
                <li>Copia de Licencia Anterior.</li>
                <li>Copia de Contrato de Arrendamiento.</li>
                </ul>`
            }
        ]
    },
    {
        main: 'Síndico Procurador',
        type: 'single',
        description: `<p><strong>Costo: </strong>Gratuito.<br>
        <strong>Horario de atención: </strong>De 8:00 a.m. a 15:00 p.m.<br>
        <strong>Domicilio donde se realiza: </strong>Blvd. Juan S. Millán y Prolongación Blvd. 20 de Noviembre, Planta Alta, Nuevo Edificio Municipal, Zona Industrial, C.P. 81000, Guasave, Sinaloa.<br>
        <strong>Tipo de Servicio: </strong>&nbsp;Presentación de Quejas y Denuncias ante la Dirección de Responsabilidades respecto a las deficiencias de la prestación de los servicios públicos y las denuncias respecto al desempeño de los empleados y funcionarios públicos cuando se poresuman actos de omisión, ineficiencia o corrupción, tales formatos se anexan de la manera siguiente:</p>`,
        forms: [
            {
                name: 'Formato de Sugerencia o Queja',
                link: 'https://firebasestorage.googleapis.com/v0/b/guasave-digital.appspot.com/o/FORMATO%20DE%20QUEJA.pdf?alt=media&token=5cadfdfc-0772-4461-9a3a-69d70e796b74'
            },
            {
                name: 'Formato de Queja o Denuncia',
                link: 'https://firebasestorage.googleapis.com/v0/b/guasave-digital.appspot.com/o/FORMATO%20QUEJA%20O%20DENUNCIA%20ADMINISTRATIVA.pdf?alt=media&token=eaf997c6-528c-4f3d-8d7a-fd320b30f181'
            }
        ]
    },
    {
        main: 'Trámite Estatal',
        list: [
            {
                name: 'Licencia de manejo',
                notes: `<p><strong>Vigencia a partir de:</strong> 12 enero de 2004.<br>
                <strong>Costo: </strong>Por primera vez y Automovilista $318. (por 2 años). Por 4 años $636.<br>
                <strong>Renovación de Licencia de Chofer:</strong> $330.00. Por 4 años $660.<br>
                <strong>Horario de Atención: </strong>De 8:00 a.m. a 3:00 p.m.<br>
                <strong>Duración de Trámite:</strong> Por primera vez 50 min. y por renovación 25 min.<br>
                <strong>Domicilio donde se realiza:</strong> Unidad Administrativa, Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga: </strong>Recaudación de Rentas en la Unidad Administrativa.`,
                reqs: `<ul>
                <li>Copia de Acta de Nacimiento.</li>
                <li>Copia de comprobante de domicilio a su nombre y reciente (Agua, luz y/o teléfono).</li>
                <li>Copia de Credencial de Elector.</li>
                </ul>`
            },
            {
                name: 'Cambio de propietario',
                notes: `<p><strong>Vigencia a partir de:</strong> 12 enero de 2004.<br>
                <strong>Costo:</strong> Variable conforme al modelo y marca de la unidad<br>
                <strong>Horario de atención:</strong> De 8:00 a.m. a 3:00 p.m.<br>
                <strong>Duración de trámite:</strong> 1 Hora.<br>
                <strong>Domicilio donde se realiza:</strong> Unidad Administrativa, Av. Adolfo López Mateos s/n, Col. del Bosque.<br>
                <strong>Lugar donde se paga:</strong> Recaudación de Rentas en la Unidad Administrativa.</p>`,
                reqs: `<ul>
                <li>Copia de la Licencia.</li>
                <li>Copia del comprobante de domicilio a su nombre y reciente (Agua, luz o teléfono).</li>
                <li>Factura original.</li>
                <li>Último pago de calca y tenencia original.</li>
                <li>2 Placas.</li>
                <li>La Unidad.</li>
                <li>Tarjeta de circulación original.</li>
                </ul>`
            }
        ]
    }
]