import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Checkbox, Container, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { areas } from '../utils/utils'

const Item = ({ item }) => {

    return (
        <TableRow>
            <TableCell>{item.name}</TableCell>
            <TableCell align="center"><Checkbox
                checked={item.isActive}
                color="primary"
                onChange={() => firebase.update('users',item.id,'isActive',!item.isActive)}
            /></TableCell>
            <TableCell align="center"><Checkbox
                checked={item.completePerms}
                color="primary"
                onChange={() => firebase.update('users',item.id,'completePerms',!item.completePerms)}
            /></TableCell>
            <TableCell>
                {item.completePerms === true ? '' : <Select
                    native
                    value={item.area}
                    onChange={e => firebase.update('users',item.id,'area',e.target.value)}
                >
                    <option aria-label="None" value="" />
                    {
                        areas.map((item,index) => <option key={index} value={item.main}>{item.main}</option>)
                    }
                </Select>}
            </TableCell>
        </TableRow>
    )
}

export default function Users () {

    const [list,setlist] = useState([])

    useEffect(() => {
        firebase.getCollection('users').where('rol','==','admin').onSnapshot(snap => {
            const data = snap.docs.map(item => ({ ...item.data(), id: item.id }))
            setlist(data)
        })
    },[])

    return (
        <Container>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell variant="head" align="center">Nombre</TableCell>
                        <TableCell variant="head" align="center">¿Activo?</TableCell>
                        <TableCell variant="head" align="center">¿Permisos Completos?</TableCell>
                        <TableCell variant="head" align="center">Área</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        list.map(item => <Item key={item.id} item={item} />)
                    }
                </TableBody>
            </Table>
        </Container>
    )
}