import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Checkbox, Container, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'

export default function SingleProcedure (props) {

    const { id } = props.match.params

    const [data,setdata] = useState({})

    useEffect(() => {
        firebase.getCollection('citas').doc(id).onSnapshot(snap => {
            setdata(snap.data())
        })
    },[id])

    return (
        <Container maxWidth="sm">
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell variant="head">Fecha</TableCell>
                        <TableCell>{moment(data.timestamp).format('DD-MM-YYYY hh:mm a')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Nombre</TableCell>
                        <TableCell>{data.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Teléfono</TableCell>
                        <TableCell>{data.phone}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Email</TableCell>
                        <TableCell>{data.email}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell variant="head">Título</TableCell>
                        <TableCell>{data.title}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Área</TableCell>
                        <TableCell>{data.area}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Fecha y Hora de Cita</TableCell>
                        <TableCell>{moment(data.date).format('DD-MM-YYYY hh:mm a')}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant="head">Marcar como finalizado</TableCell>
                        <TableCell><Checkbox
                            checked={data.isRead}
                            color="primary"
                            onChange={() => firebase.update('citas',id,'isRead',data.isRead ? !data.isRead : true)}
                        /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Container>
    )
}