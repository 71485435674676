import React, { useEffect, useState } from 'react'
import firebase from './utils/firebase'
import { Switch, Route, Link } from 'react-router-dom'
import { Divider, List, ListItem, ListItemIcon, ListItemText, AppBar, Toolbar, IconButton, Typography, Hidden, Drawer } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FolderIcon from '@material-ui/icons/Folder';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Dashboard from './pages/Dashboard'
import Reports from './pages/Reports'
import Procedure from './pages/Procedure';
import SingleReport from './pages/SingleReport';
import ProcedureArea from './pages/ProcedureArea';
import Users from './pages/Users';
import Configuration from './pages/Configuration';
import ConfigurationReportsTypes from './pages/ConfigurationReportsTypes';
import SingleProcedure from './pages/SingleProcedure';
import Emergencies from './pages/Emergencies';
import Images from './pages/Images';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
}));

export default function App (props) {

    const loc = props.location.pathname.split('/')[1]
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [user,setUser] = useState(undefined)

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.userSession().then(data => {
                    if (data.rol === "admin" && data.isActive === true) {
						setUser(data)
                    } else {
                        alert('No tienes los permisos necesarios para iniciar sesión')
                        firebase.logout().then(() => {
							props.history.push('/iniciar-sesion')
						})
                    }
                })
            } else {
				props.history.push('/iniciar-sesion')
			}
        })
    },[props.history])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem selected={loc === '' || loc === undefined ? true : false} component={Link} to="/" button>
                    <ListItemIcon><DashboardIcon/></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
				<ListItem selected={loc === 'reportes' ? true : false} component={Link} to="/reportes" button>
                    <ListItemIcon><FolderIcon/></ListItemIcon>
                    <ListItemText primary="Reportes" />
                </ListItem>
				<ListItem selected={loc === 'tramites' ? true : false} component={Link} to="/tramites" button>
                    <ListItemIcon><FileCopyIcon/></ListItemIcon>
                    <ListItemText primary="Tramites" />
                </ListItem>
            </List>
            <Divider />
            <List>
                {user?.completePerms ? <ListItem selected={loc === 'imagenes' ? true : false} component={Link} to="/imagenes" button>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Imágenes" />
                </ListItem> : ''}
                {user?.completePerms ? <ListItem selected={loc === 'configuracion' ? true : false} component={Link} to="/configuracion" button>
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Configuración" />
                </ListItem> : ''}
				{user?.completePerms ? <ListItem selected={loc === 'usuarios' ? true : false} component={Link} to="/usuarios" button>
                    <ListItemIcon><PeopleIcon/></ListItemIcon>
                    <ListItemText primary="Usuarios" />
                </ListItem> : ''}
                <ListItem selected={loc === 'emergencias' ? true : false} component={Link} to="/emergencias" button>
                    <ListItemIcon><ErrorOutlineIcon/></ListItemIcon>
                    <ListItemText primary="Emergencias" />
                </ListItem>
            </List>
        </div>
    )

    return user ? (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography style={{ color: '#fff', fontWeight: '700' }} variant="h6" noWrap>
                        Panel de Administrador
                    </Typography>
					<IconButton onClick={logOut} style={{ marginLeft: 'auto' }}>
						<ExitToAppIcon/>
					</IconButton>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {
                    <Switch>
                        <Route path="/" render={props => <Dashboard {...props} user={user} />} exact={true} />
                        <Route path="/reportes" render={props => <Reports {...props} user={user} />} exact={true} />
                        <Route path="/reportes/:id" render={props => <SingleReport {...props} user={user} />} exact={true} />
                        <Route path="/tramites" render={props => <Procedure {...props} user={user} />} exact={true} />
                        <Route path="/tramites/:area" render={props => <ProcedureArea {...props} user={user} />} exact={true} />
                        <Route path="/tramites/:area/:id" render={props => <SingleProcedure {...props} user={user} />} exact={true} />
                        <Route path="/emergencias" render={props => <Emergencies {...props} user={user} />} exact={true} />
                        <Route path="/imagenes" render={props => <Images {...props} user={user} />} exact={true} />

                        {user?.completePerms ? <Route path="/usuarios" component={Users} exact={true} /> : ''}
                        {user?.completePerms ? <Route path="/configuracion" component={Configuration} exact={true} /> : ''}
                        {user?.completePerms ? <Route path="/configuracion/tipos-reportes" component={ConfigurationReportsTypes} exact={true} /> : ''}
                    </Switch>
                }
            </main>
        </>
    ) : ''


	async function logOut () {
		try {
			firebase.logout().then(() => {
				props.history.push('/iniciar-sesion')
			})
		} catch (error) {
			alert(error.message)
		}
	}
}