import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import firebase from '../utils/firebase'
import { css } from '@emotion/core'
import _ from 'lodash'

import { areas } from '../utils/utils'
import moment from 'moment'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Item = ({ data,list }) => {

    const filter = _.filter(list, o => {
        return o.area === data.main
    })

    const complete = _.filter(filter, o => {
        return o.isRead === true
    })

    const hoursList = _.reduce(filter, (sum,n) => {
        if (n.end) {
            const diff = moment(n.end).diff(moment(n.timestamp))
            const dur = moment.duration(diff).asHours()
            return [...sum, dur]
        } else {
            return sum
        }
    },[])
    const average = _.mean(hoursList)

    return (
        <TableRow>
            <TableCell>{data.main}</TableCell>
            <TableCell align="center">{_.size(filter)}</TableCell>
            <TableCell align="center">{_.size(complete)}</TableCell>
            <TableCell align="center">{average ? average.toFixed(0) : 0} horas</TableCell>
            <TableCell align="center"><IconButton component={Link} to={`/tramites/${data.main}`} color="primary"><ArrowForwardIosIcon /></IconButton></TableCell>
        </TableRow>
    )
}

export default function Procedure (props) {

    const [list,setlist] = useState([])

    useEffect(() => {
        if (props.user.rol) {
            if (props.user?.completePerms === true) {
                firebase.getCollection('citas').orderBy('timestamp').onSnapshot(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    setlist(data)
                })
            } else {
                firebase.getCollection('citas').orderBy('timestamp').where('area','==',props.user ? props.user.area : 'none').onSnapshot(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    setlist(data)
                })
            }
        }
    }, [props.user])

    const complete = _.filter(list, o => {
        return o.isRead === true
    })
    const size = _.size(list)
    const hoursList = _.reduce(list, (sum,n) => {
        if (n.end) {
            const diff = moment(n.end).diff(moment(n.timestamp))
            const dur = moment.duration(diff).asHours()
            return [...sum, dur]
        } else {
            return sum
        }
    },[])
    const average = _.mean(hoursList)

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{size}</Typography>
                        <Typography align="center" variant="h6">Trámites Totales</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{_.size(complete)}</Typography>
                        <Typography align="center" variant="h6">Trámites Atendidos</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{size - _.size(complete)}</Typography>
                        <Typography align="center" variant="h6">Trámites Pendientes</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={3}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{average.toFixed(0)} horas</Typography>
                        <Typography align="center" variant="h6">Tiempo de Atención</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Table css={css`
                margin-top: 2rem;
            `}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center"><b>Area</b></TableCell>
                        <TableCell align="center"><b>Trámites Totales</b></TableCell>
                        <TableCell align="center"><b>Trámites Atendidos</b></TableCell>
                        <TableCell align="center"><b>Tiempo de Atención</b></TableCell>
                        <TableCell align="center"><b>Ver Más</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.user.completePerms === true ? areas.map((item,index) => <Item key={index} data={item} list={list} />) : <Item data={{ main: props.user.area }} list={list} />
                    }
                </TableBody>
            </Table>
        </Container>
    )
}