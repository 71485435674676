import { createMuiTheme } from '@material-ui/core/styles';
import { red, lime } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
	palette: {
        type: "dark",
		primary: {
			main: '#7f3035'
		},
		secondary: lime,
		error: {
			main: red.A400,
		},
	},
});

export default theme;