import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Container, Grid, Paper, TextField, Typography } from '@material-ui/core'
import firebase from '../utils/firebase'
import { css } from '@emotion/core'
import _ from 'lodash'

export default function Dashboard (props) {

    const [list,setlist] = useState([])
    const [reps,setreps] = useState(0)
    const [noreps,setnoreps] = useState(0)
    const [folio,setfolio] = useState('')

    const history = useHistory();

    useEffect(() => {
        firebase.getCollection('reportes').onSnapshot(snap => {
            setreps(snap.size)
        })
        firebase.getCollection('reportes').where('isRead','==',true).onSnapshot(snap => {
            setnoreps(snap.size)
        })
        if (props.user.rol) {
            if (props.user?.completePerms === true) {
                firebase.getCollection('citas').onSnapshot(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    setlist(data)
                })
            } else {
                firebase.getCollection('citas').where('area','==',props.user.area).onSnapshot(snap => {
                    const data = snap.docs.map(doc => doc.data())
                    setlist(data)
                })
            }
        }
    }, [props.user])

    const complete = _.filter(list, o => {
        return o.isRead === true
    })
    const size = _.size(list)

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item sm={12} md={6}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{size}</Typography>
                        <Typography align="center" variant="h6">Trámites Totales</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{_.size(complete)}</Typography>
                        <Typography align="center" variant="h6">Trámites Atendidos</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{reps}</Typography>
                        <Typography align="center" variant="h6">Reportes Creados</Typography>
                    </Paper>
                </Grid>
                <Grid item sm={12} md={6}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                    `}>
                        <Typography align="center" variant="h4">{noreps}</Typography>
                        <Typography align="center" variant="h6">Reportes Atendidos</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Grid css={css`
                margin-top: 2.5rem;
            `} alignItems="flex-end" container spacing={3}>
                <Grid item sm={12} md={8}>
                    <TextField
                        label="Buscar Folio"
                        value={folio}
                        variant="outlined"
                        fullWidth
                        onChange={e => setfolio(e.target.value)}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <Button onClick={handleSearch} fullWidth size="large" variant="contained" color="primary">Buscar</Button>
                </Grid>
            </Grid>
        </Container>
    )

    async function handleSearch () {
        try {
            if (folio) {
                firebase.getDocument('citas',folio).get().then(snap => {
                    if (snap.exists) {
                        const data = snap.data()
                        history.push(`/tramites/${data.area}/${snap.id}`)
                    } else {
                        firebase.getDocument('reportes',folio).get().then(snapChild => {
                            if (snapChild.exists) {
                                history.push(`/reportes/${snapChild.id}`)
                            } else {
                                alert('Folio no encontrado')
                            }
                        }).catch(error => {
                            alert(error.message)
                        })
                    }
                }).catch(error => {
                    alert(error.message)
                })
            } else {
                alert('Escribe un folio para continuar.')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}