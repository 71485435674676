import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Button, Container, Grid, IconButton, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { css } from '@emotion/core'

import { areas } from '../utils/utils'

import DeleteIcon from '@material-ui/icons/Delete';

export default function ConfigurationReportsTypes () {

    const [list,setlist] = useState([])
    const [title,settitle] = useState('')
    const [area,setarea] = useState('')

    useEffect(() => {
        firebase.getCollection('tiposReportes').onSnapshot(snap => {
            const data = snap.docs.map(doc => ({...doc.data(), id: doc.id}))
            setlist(data)
        })
    },[])

    return (
        <Container>
            <h3>Nuevo tipo de reporte</h3>
            <Grid component="form" onSubmit={e => e.preventDefault() && false} alignItems="flex-end" container spacing={3}>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Título"
                        value={title}
                        onChange={e => settitle(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="body1">Area</Typography>
                    <Select
                        native
                        value={area}
                        onChange={e => setarea(e.target.value)}
                        inputProps={{
                            name: 'age',
                            id: 'age-native-simple',
                        }}
                        fullWidth
                    >
                        <option aria-label="None" value="" />
                        {
                            areas.map((item,index) => <option key={index} value={item.main}>{item.main}</option>)
                        }
                    </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button onClick={handleSubmit} size="large" fullWidth color="primary" variant="contained">Agregar</Button>
                </Grid>
            </Grid>
            <Table css={css`
                margin-top: 2rem;
            `}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" variant="head" component="th">Título</TableCell>
                        <TableCell align="center" variant="head" component="th">Área</TableCell>
                        <TableCell align="center" variant="head" component="th">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        list.map((item,index) => <TableRow key={item.id}>
                            <TableCell>{item.titulo}</TableCell>
                            <TableCell>{item.area}</TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => firebase.delete('tiposReportes',item.id,item)} color="primary"><DeleteIcon /></IconButton>
                            </TableCell>
                        </TableRow>)
                    }
                </TableBody>
            </Table>
        </Container>
    )

    async function handleSubmit () {
        try {
            if (title && area) {
                const item = {
                    titulo: title,
                    area
                }
                firebase.simpleAdd(item,'tiposReportes').then(() => {
                    settitle('')
                    setarea('')
                    alert('Tipo de reporte agregado')
                }).catch(error => {
                    alert(error.message)
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}