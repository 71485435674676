import React, { useEffect, useState } from 'react'
import firebase from '../utils/firebase'
import { Checkbox, Container, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { css } from '@emotion/core'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps"
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

const MyMap = (props) => {

    const { list } = props

    const MyMapComponent = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={12}
            defaultCenter={{ lat: 25.574444, lng: -108.470555 }}
        >
            {list?.map(item => <Marker position={{ lat: item.location.coords.latitude, lng: item.location.coords.longitude }} />)}
        </GoogleMap>
    ))
    return (
        <MyMapComponent
            {...props}
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCZcVTkU1dxhjnrtW_qkS2ygZPKTrm13j8&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    )
}

const Mark = ({ data }) => {

    const [address,setaddress] = useState({})

    useEffect(() => {
        axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.location.coords.latitude},${data.location.coords.longitude}&key=AIzaSyCZcVTkU1dxhjnrtW_qkS2ygZPKTrm13j8`).then(resp => {
            const { data } = resp
            setaddress(data.results[0])
        }).catch(error => {
            console.log(error)
        })
    },[data.lat,data.lng])

    return address ? (
        <ListItem>
            <ListItemAvatar>
                <Checkbox onChange={() => firebase.update('emergency',data.id,'status',data.status === 'pending' ? 'complete' : 'pending')} checked={data.status === 'complete' ? true : false}/>
            </ListItemAvatar>
            <ListItemText primary={moment(data.timestamp).format('DD-MM-YYYY hh:mm:ss')} secondary={`${address.formatted_address} - ${data.name} - ${data.phone}`} />
        </ListItem>
    ) : ''
}

export default function Emergencies () {

    const [list,setlist] = useState([])

    useEffect(() => {
        firebase.getCollection('emergency').orderBy('timestamp','desc').onSnapshot(snap => {
            const data = snap.docs.map(doc => ({...doc.data(),id: doc.id}))
            setlist(data)
        })
    },[])

    const filter = _.filter(list, o => {
        return o.status === 'pending'
    })

    return (
        <Container>
            <MyMap list={filter} />
            <div css={css`
                margin-top: 2.5rem;
            `}>
                <h3 css={css`
                    color: #fff;
                `}>Emergencias Agregadas</h3>
                {/* <ul css={css`
                    li {
                        margin-top: 1rem;
                        line-height: 1.8;
                        b {
                            color: #fff;
                        }
                    }
                `}>
                    {
                        list.map(item => <Mark data={item} key={item.timestamp}/>)
                    }
                </ul> */}
                <List>
                    {
                        list.map(item => <Mark data={item} key={item.timestamp}/>)
                    }
                </List>
            </div>
        </Container>
    )
}