import React from 'react'
import { Link } from 'react-router-dom';
import { Container, Grid, Paper, Typography } from '@material-ui/core'
import { css } from '@emotion/core'

import GridOnIcon from '@material-ui/icons/GridOn';

export default function Configuration () {

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid component={Link} css={css`
                    text-decoration: none;
                `} to="/configuracion/tipos-reportes" item xs={12} md={4}>
                    <Paper css={css`
                        padding: 2rem 1rem;
                        text-align: center;
                    `}>
                        <GridOnIcon css={css`
                            font-size: 3.5rem;
                        `} />
                        <Typography variant="h5">Tipos de Reportes</Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}